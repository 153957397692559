import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, renderSlot as _renderSlot, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode } from "vue"

import InputMapper from '@/components/InputMapper.vue'
import FailResultModal from '@/components/modal/FailResultModal.vue'
import { computed, ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'PForm',
  props: {
    title: {
        type: String,
    },
    loading: {
        type: Boolean,
    },
    rules: {
        type: Object,
        default: () => ({}),
    },
    inputSpec: {
        type: Object,
        default: () => ({}),
    },
},
  emits: ['submit'],
  setup(__props, { emit: __emit }) {

const formRef = ref()
const failResultModal = ref()
const errorMessage = ref('')

const props = __props

const form: Record<string, any> = computed(() => {
    if (props.inputSpec == null) return {}

    return Object.keys(props.inputSpec).reduce((record, key) => {
        if (props.inputSpec[key].value == null) {
            return { ...record }
        }

        return { ...record, [key]: props.inputSpec[key].value }
    }, {})
})

const formKeys = computed<Array<string>>(() => {
    if (props.inputSpec == null) return []
    return [...new Set([...Object.keys(props.inputSpec)])]
})

const emit = __emit

const onSubmit = () => {
    return formRef.value
        ?.validate()
        .then(() => {
            return emit('submit', form.value)
        })
        .catch((error: Error) => {
            errorMessage.value =
                '입력 유효성 검증에 실패했습니다. 입력을 확인해주세요.'
            failResultModal.value.open()
            throw error
        })
}

return (_ctx: any,_cache: any) => {
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!

  return (__props.loading)
    ? (_openBlock(), _createBlock(_component_a_skeleton, { key: 0 }))
    : (_openBlock(), _createBlock(_component_a_form, {
        key: 1,
        ref_key: "formRef",
        ref: formRef,
        model: form.value,
        rules: __props.rules
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formKeys.value, (key, index) => {
            return (_openBlock(), _createBlock(InputMapper, {
              key: index,
              "input-spec": __props.inputSpec[key],
              form: form.value,
              prop: key
            }, null, 8, ["input-spec", "form", "prop"]))
          }), 128)),
          _createVNode(_component_a_form_item, { class: "footer__row" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, {
                type: "primary",
                onClick: _withModifiers(onSubmit, ["prevent"]),
                "html-type": "submit",
                loading: __props.loading
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "button")
                ]),
                _: 3
              }, 8, ["loading"]),
              _renderSlot(_ctx.$slots, "action", {
                form: form.value,
                loading: __props.loading
              })
            ]),
            _: 3
          }),
          _createVNode(FailResultModal, {
            ref_key: "failResultModal",
            ref: failResultModal,
            title: `${__props.title} 실패`,
            "error-message": errorMessage.value
          }, null, 8, ["title", "error-message"])
        ]),
        _: 3
      }, 8, ["model", "rules"]))
}
}

})