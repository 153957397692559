import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import AdminLogin from '@/views/AdminLogin.vue'
import Admin from '@/views/Admin.vue'
import Products from '@/views/Products.vue'
import UserFeedback from '@/views/UserFeedback.vue'
import Nutrient from '@/views/Nutrient.vue'
import IntakeAdvisor from '@/views/IntakeAdvisor.vue'
import NutrientAllowance from '@/views/NutrientAllowance.vue'
import HealthStatus from '@/views/HealthStatus.vue'
import Comment from '@/views/Comment.vue'
import ConsumerGuide from '@/views/ConsumerGuide.vue'
import ConsumerGuideDoc from '@/views/ConsumerGuideDoc.vue'
import Review from '@/views/Review.vue'
import ProductReview from '@/views/ProductReview.vue'
import Question from '@/views/Question.vue'
import Hig from '@/views/Hig.vue'
import ReviewTag from '@/views/ReviewTag.vue'
import NutrientAllowanceGroup from '@/views/NutrientAllowanceGroup.vue'
import NutrientGroup from '@/views/NutrientGroup.vue'
import StandardUnit from '@/views/StandardUnit.vue'
import ProductTag from '@/views/ProductTag.vue'
import User from '@/views/User.vue'
import EventToast from '@/views/EventToast.vue'
import EventView from '@/views/EventView.vue'
import FileManager from '@/views/FileManager.vue'
import AdminNotification from '@/views/AdminNotification.vue'
import PillyzeColumn from '@/views/PillyzeColumn.vue'
import PillyzePress from '@/views/PillyzePress.vue'
import Home from '@/views/Home.vue'
import ProductsEdit from '@/views/ProductsEdit.vue'
import NutrientGoodPoint from '@/views/NutrientGoodPoint.vue'
import Brand from '@/views/Brand.vue'
import FoodIntakeAdvisor from '@/views/FoodIntakeAdvisor.vue'
import FoodNutrientGoal from '@/views/FoodNutrientGoal.vue'
import Diet from '@/views/Diet.vue'
import EventUser from '@/views/EventUser.vue'
import EventUserAlarm from '@/views/EventUserAlarm.vue'
import FoodUserRequest from '@/views/FoodUserRequest.vue'
import FoodManagement from '@/views/FoodManagement.vue'
import FoodEditVue from '@/views/FoodEdit.vue'
import FoodGuideDoc from '@/views/FoodGuideDoc.vue'
import FoodDictionary from '@/views/FoodDictionary.vue'
import FoodSynonym from '@/views/FoodSynonym.vue'
import Popup from '@/views/Popup.vue'
import UserSegment from '@/views/UserSegment.vue'
import SearchAd from '@/views/SearchAd.vue'
import AndyChance from '@/views/AndyChance.vue'
import NotificationDevtool from '@/views/NotificationDevtool.vue'
import TriggerBox from '@/views/TriggerBox.vue'
import OpenAiChat from '@/views/OpenAiChat.vue'
import FoodManagementByPriority from '@/views/FoodManagementByPriority.vue'
import WorkoutManager from '@/views/WorkoutManager.vue'
import WorkoutTrackerActivityManager from '@/views/WorkoutTrackerActivityManager.vue'
import AiCoachingSchedule from '@/views/AiCoachingSchedule.vue'
import LibrePractice from '@/views/LibrePractice.vue'
import SurveyView from '@/views/SurveyView.vue'
import AICoachingQATool from '@/views/AICoachingQATool.vue'
import PillyzeImageAsset from '@/views/PillyzeImageAsset.vue'
import FoodLensMap from '@/views/FoodLensMap.vue'
import IntakeUnit from '@/views/IntakeUnit.vue'
import AdRevenue from '@/views/AdRevenue.vue'
import AdSlot from '@/views/ad/AdSlot.vue'
import AdLayout from '@/views/ad/AdLayout.vue'
import ProductSearchKeywordRank from '@/views/ProductSearchKeywordRank.vue'
import QaTool from '@/views/QaTool.vue'

import { useUserStore } from '@/store/user'
import AdMain from '@/views/ad/AdMain.vue'
import PointShopProducts from '@/views/PointShopProducts.vue'
import PointShopOrders from '@/views/PointShopOrders.vue'
import PointShopProductsCategory from '@/views/PointShopProductsCategory.vue'
import AdBenefitScreen from '@/views/ad/benefit/AdBenefitScreen.vue'
import AdBenefitSection from '@/views/ad/benefit/AdBenefitSection.vue'
import BannerCreator from '@/views/BannerCreator.vue'
import CrmTemplates from '@/views/CrmTemplates.vue'
import EventUserSegment from '@/views/EventUserSegment.vue'
import AdminReleaseNotification from '@/views/AdminReleaseNotification.vue'
import UserDietProgram from '@/views/UserDietProgram.vue'
import UserDietProgramEdit from '@/views/UserDietProgramEdit.vue'

const adminRoutes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/formeDiet',
        name: 'UserDietProgram',
        component: UserDietProgram,
    },
    {
        path: '/chat/:userId',
        name: 'ChatDialogue',
        component: () => import('@/views/ChatDialogue.vue'),
        props: (route) => ({
            menu: route.query.menu,
            userId: route.params.userId,
        }),
    },
    {
        path: '/formeDiet/edit/:userId',
        name: 'UserDietProgramEdit',
        component: UserDietProgramEdit,
        props: true,
    },
    {
        path: '/userFeedback',
        name: 'UserFeedback',
        component: UserFeedback,
        children: [
            {
                path: 'products/edit/:resourceId',
                component: ProductsEdit,
                props: true,
            },
        ],
    },
    {
        path: '/products',
        name: 'Products',
        component: Products,
        children: [
            {
                path: 'edit/:resourceId',
                component: ProductsEdit,
                props: true,
            },
        ],
    },
    {
        path: '/brand',
        name: 'Brand',
        component: Brand,
    },
    {
        path: '/nutrient',
        name: 'Nutrient',
        component: Nutrient,
    },
    {
        path: '/intakeAdvisor',
        name: 'IntakeAdvisor',
        component: IntakeAdvisor,
    },
    {
        path: '/nutrientAllowance',
        name: 'NutrientAllowance',
        component: NutrientAllowance,
    },
    {
        path: '/healthStatus',
        name: 'HealthStatus',
        component: HealthStatus,
    },
    {
        path: '/review',
        name: 'Review',
        component: Review,
    },
    {
        path: '/product/reviews',
        name: 'Product Review',
        component: ProductReview,
    },
    {
        path: '/comment',
        name: 'Comment',
        component: Comment,
    },
    {
        path: '/hig',
        name: 'hig',
        component: Hig,
    },
    {
        path: '/consumerGuide',
        name: 'ConsumerGuide',
        component: ConsumerGuide,
    },
    {
        path: '/column',
        name: 'column',
        component: PillyzeColumn,
    },
    {
        path: '/press',
        name: 'press',
        component: PillyzePress,
    },
    {
        path: '/nutrientGoodPoint',
        name: 'NutrientGoodPoint',
        component: NutrientGoodPoint,
    },
    {
        path: '/consumerGuideDoc',
        name: 'ConsumerGuideDoc',
        component: ConsumerGuideDoc,
    },
    {
        path: '/reviewTag',
        name: 'ReviewTag',
        component: ReviewTag,
    },
    {
        path: '/question',
        name: 'Question',
        component: Question,
    },
    {
        path: '/nutrientAllowanceGroup',
        name: 'NutrientAllowanceGroup',
        component: NutrientAllowanceGroup,
    },
    {
        path: '/nutrientGroup',
        name: 'NutrientGroup',
        component: NutrientGroup,
    },
    {
        path: '/standardUnit',
        name: 'StandardUnit',
        component: StandardUnit,
    },
    {
        path: '/productTag',
        name: 'ProductTag',
        component: ProductTag,
    },
    {
        path: '/user',
        name: 'User',
        component: User,
    },
    {
        path: '/event',
        name: 'EventView',
        component: EventView,
    },
    {
        path: '/eventUser',
        name: 'EventUser',
        component: EventUser,
    },
    {
        path: '/eventUserSegment',
        name: 'EventUserSegment',
        component: EventUserSegment,
    },
    {
        path: '/eventUserAlarm',
        name: 'EventUserAlarm',
        component: EventUserAlarm,
    },
    {
        path: '/eventToast',
        name: 'EventToast',
        component: EventToast,
    },
    {
        path: '/popup',
        name: 'Popup',
        component: Popup,
    },
    {
        path: '/productSearchKeywordRank',
        name: 'ProductSearchKeywordRank',
        component: ProductSearchKeywordRank,
    },
    {
        path: '/userSegment',
        name: 'UserSegment',
        component: UserSegment,
    },
    {
        path: '/crm-templates',
        name: 'CrmTemplates',
        component: CrmTemplates,
    },
    {
        path: '/searchAd',
        name: 'SearchAd',
        component: SearchAd,
    },
    {
        path: '/fileManager',
        name: 'FileManager',
        component: FileManager,
    },
    {
        path: '/adminNotification',
        name: 'AdminNotification',
        component: AdminNotification,
    },
    {
        path: '/notification/release',
        name: 'AdminReleaseNotification',
        component: AdminReleaseNotification,
    },
    {
        path: '/foodIntakeAdvisor',
        name: 'FoodIntakeAdvisor',
        component: FoodIntakeAdvisor,
    },
    {
        path: '/foodNutrientGoal',
        name: 'FoodNutrientGoal',
        component: FoodNutrientGoal,
    },
    {
        path: '/foodManagementByPriority',
        name: 'FoodManagementByPriority',
        component: FoodManagementByPriority,
    },
    {
        path: '/diet',
        name: 'diet',
        component: Diet,
    },
    {
        path: '/foodDictionary',
        name: 'foodDictionary',
        component: FoodDictionary,
    },
    {
        path: '/foodSynonym',
        name: 'foodSynonym',
        component: FoodSynonym,
    },
    {
        path: '/foodGuideDoc',
        name: 'FoodGuideDoc',
        component: FoodGuideDoc,
    },
    {
        path: '/foodUserRequest',
        name: 'FoodUserRequest',
        component: FoodUserRequest,
    },
    {
        path: '/food',
        name: 'Food',
        component: FoodManagement,
        children: [
            {
                path: 'edit/:resourceId',
                component: FoodEditVue,
                props: true,
            },
        ],
        props: (route) => ({ foodName: route.query.foodName }),
    },
    {
        path: '/andyChance',
        name: 'AndyChance',
        component: AndyChance,
    },
    {
        path: '/notificationDevtool',
        name: 'NotificationDevtool',
        component: NotificationDevtool,
    },
    {
        path: '/triggerBox',
        name: 'TriggerBox',
        component: TriggerBox,
    },
    {
        path: '/openAiChat',
        name: 'OpentAiChat',
        component: OpenAiChat,
    },
    {
        path: '/workout',
        name: 'Workout',
        component: WorkoutManager,
    },
    {
        path: '/workoutTrackerActivity',
        name: 'WorkoutTrackerActivity',
        component: WorkoutTrackerActivityManager,
    },
    {
        path: '/aiCoachingSchedule',
        name: 'AiCoachingSchedule',
        component: AiCoachingSchedule,
    },
    {
        path: '/librePractice',
        name: 'LibrePractice',
        component: LibrePractice,
    },
    {
        path: '/survey',
        name: 'Survey',
        component: SurveyView,
    },
    {
        path: '/aiCoachingQATool',
        name: 'AICoachingQATool',
        component: AICoachingQATool,
    },
    {
        path: '/pillyzeImageAsset',
        name: 'PillyzeImageAsset',
        component: PillyzeImageAsset,
    },
    {
        path: '/foodLensMap',
        name: 'FoodLensMap',
        component: FoodLensMap,
    },
    {
        path: '/intakeUnit',
        name: 'IntakeUnit',
        component: IntakeUnit,
    },
    {
        path: '/adRevenue',
        name: 'AdRevenue',
        component: AdRevenue,
    },
    {
        path: '/ad/slot',
        name: 'AdSlot',
        component: AdSlot,
    },
    {
        path: '/ad/layout',
        name: 'AdLayout',
        component: AdLayout,
    },
    {
        path: '/ad/advertise',
        name: 'AdMain',
        component: AdMain,
    },
    {
        path: '/ad/benefit/screen',
        name: 'AdBenefitScreen',
        component: AdBenefitScreen,
    },
    {
        path: '/ad/benefit/section',
        name: 'AdBenefitSection',
        component: AdBenefitSection,
    },
    {
        path: '/point-shop/products',
        name: 'PointShopProducts',
        component: PointShopProducts,
    },
    {
        path: '/point-shop/orders',
        name: 'PointShopOrders',
        component: PointShopOrders,
    },
    {
        path: '/point-shop/products/category',
        name: 'PointShopProductsCategory',
        component: PointShopProductsCategory,
    },
    {
        path: '/qaTool',
        name: 'QaTool',
        component: QaTool,
    },
    {
        path: '/banner/creator',
        name: 'BannerCreator',
        component: BannerCreator,
    },
]

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        component: Admin,
        children: adminRoutes,
    },
    {
        path: '/login',
        name: 'AdminLogin',
        component: AdminLogin,
        props: (route) => ({ code: route.query.code, provider: 'kakao' }),
    },
    {
        path: '/login/cognito',
        component: AdminLogin,
        props: (route) => ({ code: route.query.code, provider: 'cognito' }),
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach((to, from, next) => {
    const userStore = useUserStore()
    const isLogin = userStore.cognitoUsername !== null

    if (to.path !== '/login/cognito' && !isLogin) {
        next({ path: '/login/cognito' })
    } else {
        next()
    }
})

export default router
