import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '@/assets/global.scss'
import 'highlight.js/styles/ocean.css'

import Antd from 'ant-design-vue'
import Vue3Lottie from 'vue3-lottie'

import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import VueTippy from 'vue-tippy'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/shift-toward-subtle.css'
import './index.css'
import VueDOMPurifyHTML from 'vue-dompurify-html'

const app = createApp(App)

//app.config.productionTip = false

app.use(Antd)
    .use(store)
    .use(router)
    .use(VueTippy, {
        arrow: true,
        directive: 'tippy', // => v-tippy
        component: 'tippy', // => <tippy/>
        componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
        defaultProps: {
            theme: 'dark',
            placement: 'auto',
            allowHTML: true,
            delay: 100,
            animation: 'shift-toward-subtle',
            appendTo: () => document.querySelector('body'),
        }, // => Global default options * see all props
    })
    .use(VueDOMPurifyHTML)
//@ts-ignore
app.use(Vue3Lottie, { name: 'Vue3Lottie' })

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

app.use(pinia)
app.mount('#app')
