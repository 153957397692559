<template>
    <a-space>
        <a-button-group>
            <CreateFormModal
                v-if="createSpec"
                :title="createModalTitle || `${title} 생성`"
                :label="`${createModalTitle || title} 등록`"
                :uri="uri"
                :input-spec="createSpec"
                :rules="createRules"
                @after-submit="fetchData"
            />
        </a-button-group>
        <slot name="toolbar" />
        <a-button v-if="exportable" :href="csvExportUrl">
            <template #icon>
                <download-outlined />
            </template>
            CSV 다운로드
        </a-button>
    </a-space>
    <a-table
        :columns="columns"
        :data-source="tableData"
        :loading="loadingDataSource"
        :scroll="scroll"
        size="small"
        :pagination="false"
        style="padding: 20px 0 20px 0"
        bordered
    >
        <template #title>
            <b>{{ title }}</b>
        </template>
        <template #tags="tags">
            <span>
                <a-tag v-for="tag in tags" :key="tag" color="blue">{{
                    tag
                }}</a-tag>
            </span>
        </template>
        <template #bodyCell="{ column, record }">
            <a-space v-if="column.type === 'edit'">
                <UpdateFormModal
                    :title="updateModalTitle || `${title} 수정`"
                    :resource-id="record[primaryKey]"
                    :uri="uri"
                    :input-spec="updateSpec!!"
                    :rules="updateRules"
                    :params="params"
                    @after-submit="fetchData"
                >
                    <template #header="data">
                        <slot name="updateModalHeader" v-bind="data" />
                    </template>
                    <template #footer="data">
                        <slot name="updateModalFooter" v-bind="data" />
                    </template>
                </UpdateFormModal>
            </a-space>
        </template>
    </a-table>
</template>

<script lang="ts">
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { stringify } from 'qs'
import { defineComponent, ref } from 'vue'
import { DownloadOutlined } from '@ant-design/icons-vue'
import CreateFormModal from '@/components/modal/CreateFormModal.vue'
import UpdateFormModal from '@/components/modal/UpdateFormModal.vue'

export default defineComponent({
    name: 'NestedResourceTable',
    components: {
        UpdateFormModal,
        CreateFormModal,
        DownloadOutlined,
    },

    props: {
        columns: {
            type: Array,
            required: true,
        },
        uri: {
            type: String,
            required: true,
        },
        params: {
            type: Object,
            default: () => ({}),
        },
        title: {
            type: String,
            required: true,
        },
        scroll: {
            type: Object,
            default: () => ({ y: 400 }),
        },
        createSpec: {
            type: Object,
        },
        updateSpec: {
            type: Object,
        },
        primaryKey: {
            type: String,
            default: 'id',
        },
        createRules: {
            type: Object,
            default: () => ({}),
        },
        updateRules: {
            type: Object,
            default: () => ({}),
        },
        createModalTitle: {
            type: String,
        },
        updateModalTitle: {
            type: String,
        },
        exportable: {
            type: Boolean,
            default: false,
        },
        csvExportUrl: {
            type: String,
        },
        loadOnMount: {
            type: Boolean,
            default: true,
        },
    },

    setup() {
        const loadingDataSource = ref(false)
        const tableData = ref([])

        return { loadingDataSource, tableData }
    },

    methods: {
        async fetchData() {
            this.loadingDataSource = true
            const result = await adminApi<ApiResponse<Record<string, any>>>(
                `${this.uri}?${stringify(this.params, { indices: false })}`
            )

            if (!result.success) {
                console.error(result.errorMessage)
            }
            this.tableData = result?.result?.content || result?.result
            this.loadingDataSource = false
        },
    },

    mounted() {
        if (this.loadOnMount) {
            this.fetchData().then()
        }
    },
})
</script>
