<script setup lang="ts">
import { defineEmits, defineProps, onMounted } from 'vue'
import globalFormState from '@/components/globalFormState'
import { useFormeWeekStore } from '@/store/formeWeek'

const props = defineProps({
    value: {
        type: [String, Number],
    },
    userId: {
        type: Number,
        required: true,
    },
    resourceId: {
        type: String,
    },
})

const emits = defineEmits(['update:value', 'afterUpdate'])

const { formeWeek, selectWeek } = useFormeWeekStore()

const { form } = globalFormState

const onUpdate = async (value: string | number) => {
    selectWeek(value)
    emits('update:value', value)
    emits('afterUpdate', value, props.resourceId)
}

const thisWeekCss = {
    color: '#0076FF',
    fontWeight: 'bold',
}

const disableButton = (weekStartDate: Date, weekEndDate: Date) => {
    if (form?.value.startDate && form?.value.endDate) {
        return (
            weekEndDate < form.value.startDate ||
            weekStartDate > form.value.endDate
        )
    }
}
</script>

<template>
    <a-radio-group :value="value" @update:value="onUpdate">
        <a-radio-button
            v-for="week in formeWeek.formeWeek"
            :key="week.weekNumber"
            :value="week.weekNumber"
            :disabled="disableButton(week.startDate, week.endDate)"
            :style="week.thisWeek ? thisWeekCss : null"
        >
            {{ week.weekNumber }}주차
            <a-badge
                v-if="week.hasUserFeedback"
                color="blue"
                style="width: 0"
                dot
            />
        </a-radio-button>
    </a-radio-group>
</template>
