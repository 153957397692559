<script setup lang="ts">
import { ref } from 'vue'
import {
    FormSpecification,
    InputComponents,
    InputType,
} from '@/components/InputMapper.vue'
import PForm from '@/components/PForm.vue'
import { adminApi, ApiResponse } from '@/fetchTemplate'

const accessTokenInputSpec = ref<FormSpecification>({
    userId: {
        label: 'User ID',
        value: null,
        component: InputComponents.AInputNumber,
    },
    accessToken: {
        label: 'Access Token',
        value: null,
        copy: true,
    },
})

const secureUserIdInputSpec = ref<FormSpecification>({
    userId: {
        label: 'User ID',
        value: null,
        component: InputComponents.AInputNumber,
    },
    secureUserId: {
        label: 'Secure User ID',
        value: null,
        copy: true,
    },
})

const chatbotInputSpec = ref<FormSpecification>({
    question: {
        label: 'Question',
        value: '',
        type: InputType.TEXTAREA,
    },
    answer: {
        label: 'Answer',
        value: '',
        copy: true,
        disabled: false,
        type: InputType.TEXTAREA,
        help: 'ex) 123 유저의 토큰은?, 123 유저의 상세정보, 123 유저의 전화번호, 123 유저에게 무료체험권 줘, 123 유저 강퇴, 01033340782 가진 사용자 id는?',
        height: '200px',
    },
})

const accessTokenRules = {
    userId: {
        required: true,
    },
}

const chatbotRules = {
    question: {
        required: true,
        message: 'Question cannot be empty',
    },
}

const loading = ref(false)

const fetchAccessToken = async (form: Record<string, any>) => {
    loading.value = true
    const response = await adminApi<ApiResponse<string>>(
        `/api/devtool/access-token/${form.userId}`
    )
    accessTokenInputSpec.value.accessToken.value = response.result
    loading.value = false
}

const fetchSecureUserId = async (form: Record<string, any>) => {
    loading.value = true
    const response = await adminApi<ApiResponse<string>>(
        `/api/devtool/secure-user-id/${form.userId}`
    )
    secureUserIdInputSpec.value.secureUserId.value = response.result
    loading.value = false
}

const fetchChatbotAnswer = async (form: Record<string, any>) => {
    loading.value = true
    const response = await adminApi<ApiResponse<string>>(
        '/api/devtool/ask-ai',
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', // Content-Type 변경
            },
            body: JSON.stringify({ question: form.question }),
        }
    )
    chatbotInputSpec.value.answer.value = response.result
    loading.value = false
}
</script>

<template>
    <div>
        <a-page-header title="통합 QA 도구" />
        <a-card title="Access Token 조회">
            <p-form
                title="Access Token 조회"
                :rules="accessTokenRules"
                :input-spec="accessTokenInputSpec"
                :loading="loading"
                @submit="fetchAccessToken"
            >
                <template #button>토큰발급</template>
            </p-form>
        </a-card>
        <a-card title="ChatPillyze">
            <p-form
                title="ChatPillyze"
                :rules="chatbotRules"
                :input-spec="chatbotInputSpec"
                :loading="loading"
                @submit="fetchChatbotAnswer"
            >
                <template #button>질의</template>
            </p-form>
        </a-card>
        <a-card title="Secure User id 조회">
            <p-form
                title="Secure User id 조회"
                :rules="accessTokenRules"
                :input-spec="secureUserIdInputSpec"
                :loading="loading"
                @submit="fetchSecureUserId"
            >
                <template #button>secureUserId 조회</template>
            </p-form>
        </a-card>
    </div>
</template>
