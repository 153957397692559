<template>
    <div>
        <a-page-header title="AI 코칭 QA 도구">
            <template #extra>
                <a-form-item label="대상 사용자">
                    <a-input-number
                        v-model:value="targetUserId"
                        @change="changeTargetUserId"
                    />
                </a-form-item>
                <a-form-item label="빌드 번호">
                    <a-input-number
                        v-model:value="buildNumber"
                        @change="changeBuildNumber"
                    />
                </a-form-item>
            </template>
        </a-page-header>
        <div style="width: 100%">
            <a-row style="margin-bottom: 20px">
                <Line v-if="glucoseMetrics" :data="glucoseMetrics" />
            </a-row>
            <a-row :gutter="16" style="margin-bottom: 20px">
                <a-col :span="8">
                    <a-card title="혈당 데이터 생성">
                        <PForm
                            title="혈당 데이터 생성"
                            :input-spec="glucoseSpec"
                            :rules="glucoseRules"
                            :loading="generateGlucoseLoading"
                            @submit="generateGlucose"
                        >
                            <template #button>생성</template>
                            <template #action="{ form }">
                                <a-button
                                    @click="() => fetchReport(form.targetDate)"
                                    :disabled="form.targetDate == null"
                                    >혈당 조회
                                </a-button>
                            </template>
                        </PForm>
                    </a-card>
                </a-col>
                <a-col :span="8">
                    <a-card title="AI 코칭 갱신">
                        대상 시간에 혈당 리포트를 기반으로 AI 코칭 이벤트를
                        발행한다.
                        <PForm
                            title="AI 코칭 갱신"
                            :input-spec="aiCoachingSpec"
                            :rules="aiCoachingRules"
                            :loading="aiCoachingScanLoading"
                            @submit="(form) => scanEvent(form.targetAt)"
                            style="margin-top: 20px"
                        >
                            <template #button>갱신</template>
                        </PForm>
                    </a-card>
                </a-col>
            </a-row>
            <a-row style="margin-bottom: 20px">
                <a-card v-if="floatingEvent" title="플로팅 이벤트">
                    <a-form-item label="eventName"
                        >{{ floatingEvent.eventName }}
                    </a-form-item>
                    <a-form-item label="floatingMessage"
                        >{{ floatingEvent.floatingMessage }}
                    </a-form-item>
                </a-card>
            </a-row>
        </div>
        <ResourceTable
            ref="cgmResourceTable"
            resource-name="cgm-device"
            title="CGM 센서"
            uri="/api/ai-coaching-schedule/cgm-device"
            :columns="deviceColumn"
            :filter-spec="filterSpec"
            :update-spec="deviceSpec"
            :update-rules="deviceRules"
            deletable
            load-on-mount
            primary-key="tsid"
        />
        <ResourceTable
            title="CGM 센서 변경이력"
            uri="/api/admin-audit"
            :columns="auditColumn"
            :filter-spec="auditFilterSpec"
            load-on-mount
            :scroll="{ x: 1100 }"
        />
        <ResourceTable
            ref="resourceTable"
            resource-name="ai-coaching-event-log"
            title="AI 코칭 이벤트 로그"
            uri="/api/ai-coaching-schedule/ai-coaching-event-log"
            :filter-spec="aiCoachingLogFilterSpec"
            :columns="columns"
            load-on-mount
            :pageable="false"
            :scroll="{ x: 3000 }"
        >
            <template #actions>
                <a-button @click="deleteAiCoachingEventLog"
                    >로그 초기화
                </a-button>
                <a-button @click="fetchFloatingEvent"
                    >플로팅 이벤트 조회
                </a-button>
            </template>
        </ResourceTable>
    </div>
</template>

<script setup lang="ts">
import { InputComponents } from '@/components/InputMapper.vue'
import ResourceTable from '@/components/ResourceTable.vue'
import PForm from '@/components/PForm.vue'
import { adminApi, ApiResponse } from '@/fetchTemplate'
import {
    formatBoolean,
    formatEnum,
    formatLocalDateTime,
} from '@/util/formmater'
import { Column } from '@/views'
import { onMounted, ref } from 'vue'
import { debounce } from 'lodash'
import { stringify } from 'qs'
import { required } from '@/util/input-validation'
import { Line } from 'vue-chartjs'
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js'
import { useEnumTypeStore } from '@/store/enumType'
import Moment from 'moment'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

const cgmResourceTable = ref()
const resourceTable = ref()
const queryParams = ref()

const targetUserId = ref()
const buildNumber = ref()

const filterSpec = ref({
    userId: {
        label: '사용자 ID',
        value: null,
    },
})

const aiCoachingLogFilterSpec = ref({
    userId: {
        label: '사용자 ID',
        value: null,
    },
    eventName: {
        label: '이벤트 이름',
        value: null,
    },
})

const changeTargetUserId = debounce(async (value: number | null) => {
    queryParams.value = {
        ...queryParams.value,
        userId: value,
    }
    //@ts-ignore
    filterSpec.value.userId.value = value
    resourceTable.value.reloadPage()
    cgmResourceTable.value.reloadPage()
}, 500)

const changeBuildNumber = async (value: number | null) => {
    queryParams.value = {
        ...queryParams.value,
        buildNumber: value,
    }
    await fetchFloatingEvent()
}

const auditFilterSpec = ref({
    resourceId: {
        label: '사용자 ID',
        value: filterSpec.value.userId.value || targetUserId,
    },
    action: {
        label: 'Action',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'admin-audit-action',
    },
    resource: {
        label: 'Resource',
        value: 'UserCgmDevice',
        disabled: true,
    },
})

const auditColumn = [
    {
        title: 'ID',
        dataIndex: 'id',
        sorter: true,
        fixed: 'left',
        width: 100,
    },
    {
        title: 'Action',
        dataIndex: 'action',
        customRender: formatEnum('admin-audit-action'),
        width: 70,
    },
    {
        title: 'Parameter',
        dataIndex: 'parameter',
        customRender: (col: any) => {
            if (col.value.endAt == null) return ''
            return `
            시작일시: ${Moment(col.value.endAt).format('YYYY-MM-DD HH:mm:ss')}
            종료일시: ${Moment(col.value.endAt).format('YYYY-MM-DD HH:mm:ss')}
            `
        },
    },
    {
        title: '등록자',
        dataIndex: 'createdBy',
        width: 100,
    },
    {
        title: '등록일시',
        dataIndex: 'creationTime',
        customRender: formatLocalDateTime,
        width: 165,
        sorter: true,
        align: 'center',
    },
    {
        title: '수정자',
        dataIndex: 'updatedBy',
        width: 100,
    },
    {
        title: '수정일시',
        dataIndex: 'updateTime',
        customRender: formatLocalDateTime,
        width: 165,
        sorter: true,
        align: 'center',
    },
]

const deviceColumn = [
    {
        title: 'ID',
        dataIndex: 'tsid',
        width: 120,
        fixed: 'left',
        scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
        },
        sorter: true,
    },
    {
        title: '센서 타입',
        dataIndex: 'deviceType',
        customRender: formatEnum('cgm-device-type'),
        width: 50,
    },
    {
        title: '자동 등록',
        dataIndex: 'autoRegister',
        customRender: formatBoolean,
        width: 60,
    },
    {
        title: '무료 체험',
        dataIndex: 'freeTrial',
        customRender: formatBoolean,
        width: 60,
    },
    {
        title: '센서 상태',
        dataIndex: 'sensorStatus',
        width: 60,
    },
    {
        title: '시작일시',
        dataIndex: 'startAt',
        customRender: formatLocalDateTime,
        width: 100,
    },
    {
        title: '종료일시',
        dataIndex: 'endAt',
        customRender: formatLocalDateTime,
        width: 100,
    },
]

const deviceSpec = ref({
    startAt: {
        label: '시작일시',
        value: null,
        component: InputComponents.ADatetimePicker,
    },
    endAt: {
        label: '종료일시',
        value: null,
        component: InputComponents.ADatetimePicker,
    },
    deviceType: {
        label: '센서 타입',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'cgm-device-type',
    },
})

const deviceRules = {
    startAt: [required],
    endAt: [required],
    devcieType: [required],
}

const glucoseSpec = ref({
    targetDate: {
        label: '대상일',
        value: null,
        component: InputComponents.ADatePicker,
    },
})

const glucoseRules = {
    targetDate: [required],
}

const generateGlucoseLoading = ref(false)

const generateGlucose = async () => {
    try {
        generateGlucoseLoading.value = true
        await adminApi<ApiResponse<void>>(
            `/api/ai-coaching-schedule/generate-mock-glucose`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userId: targetUserId.value,
                    targetDate: glucoseSpec.value.targetDate.value,
                }),
            }
        )
    } finally {
        generateGlucoseLoading.value = false
    }
}

const deleteAiCoachingEventLog = async () => {
    await adminApi<ApiResponse<void>>(
        `/api/ai-coaching-schedule/ai-coaching-event-log?${stringify(
            queryParams.value
        )}`,
        {
            method: 'DELETE',
        }
    )

    resourceTable.value.reloadPage()
}

const scanEvent = async (targetAt: any) => {
    aiCoachingScanLoading.value = true
    try {
        aiCoachingScanLoading.value = true
        await adminApi<ApiResponse<void>>(
            `/api/ai-coaching-schedule/scan-event?targetAt=${targetAt}&${stringify(
                queryParams.value
            )}`
        )
    } finally {
        aiCoachingScanLoading.value = false
    }
}

const floatingEvent = ref()

const fetchFloatingEvent = async () => {
    floatingEvent.value = null
    const response = await adminApi<ApiResponse<any>>(
        `/api/ai-coaching-schedule/floating-event?${stringify(
            queryParams.value
        )}`
    )
    floatingEvent.value = response.result
}

const glucoseMetrics = ref<any>()

const fetchReport = async (targetDate: any) => {
    const response = await adminApi<ApiResponse<any>>(
        `/api/ai-coaching-schedule/report?targetDate=${targetDate}&${stringify(
            queryParams.value
        )}`
    )
    response.result

    if (response.result != null && response.result.interpolatedPoints != null) {
        const points = response.result.interpolatedPoints
        const labels = points.map((it: any) => it.dt)
        const data = points.map((it: any) => it.y)

        glucoseMetrics.value = {
            labels,
            datasets: [
                {
                    label: '혈당',
                    data,
                    borderColor: '#f87979',
                },
            ],
        }
    }
}

const aiCoachingSpec = ref({
    targetAt: {
        label: '대상일시',
        value: null,
        component: InputComponents.ADatetimePicker,
    },
})

const aiCoachingRules = {
    targetAt: [required],
}

const aiCoachingScanLoading = ref(false)

const columns: Array<Column> = [
    {
        title: 'eventName',
        dataIndex: 'eventName',
        fixed: 'left',
        width: 100,
    },
    {
        title: 'triggerCount',
        dataIndex: 'triggerCount',
    },
    {
        title: 'createdAt',
        dataIndex: 'createdAt',
        customRender: formatLocalDateTime,
        width: 140,
    },
    {
        title: 'triggerAt',
        dataIndex: 'triggerAt',
        customRender: formatLocalDateTime,
        width: 140,
        sorter: true,
    },
    {
        title: 'done',
        dataIndex: 'done',
        customRender: formatBoolean,
    },
    {
        title: 'contentTitle',
        dataIndex: 'contentTitle',
        width: 300,
    },
    {
        title: 'floatingMessage',
        dataIndex: 'floatingMessage',
        width: 300,
    },
    {
        title: 'floatingButton',
        dataIndex: 'floatingButton',
        width: 150,
    },
    {
        title: 'notificationTitle',
        dataIndex: 'notificationTitle',
        width: 150,
    },
    {
        title: 'notificationMessage',
        dataIndex: 'notificationMessage',
        width: 200,
    },
    {
        title: 'andyIconUrl',
        dataIndex: 'andyIconUrl',
        type: 'img',
        width: 130,
    },
    {
        title: 'showCount',
        dataIndex: 'showCount',
        width: 100,
    },
    {
        title: 'aiCoachingType',
        dataIndex: 'aiCoachingType',
        customRender: formatEnum('ai-coaching-type'),
        width: 150,
        align: 'center',
    },
    {
        title: 'timelineTitle',
        dataIndex: 'timelineTitle',
        width: 150,
        align: 'center',
    },
    {
        title: 'timelineIcon',
        dataIndex: 'timelineIcon',
        type: 'img',
        width: 130,
    },
    {
        title: 'timelineContent',
        dataIndex: 'timelineContent',
        width: 300,
    },
    {
        title: 'timelineImage',
        dataIndex: 'timelineImage',
        type: 'img',
        width: 130,
    },
    {
        title: 'timelineAnswer',
        dataIndex: 'timelineAnswer',
        width: 300,
    },
    {
        title: 'timelineButton',
        dataIndex: 'timelineButton',
        width: 150,
    },
    {
        title: 'priority',
        dataIndex: 'priority',
        sorter: true,
        fixed: 'right',
    },
]

onMounted(() => {
    const enumTypeStore = useEnumTypeStore()
    enumTypeStore.dispatchEnums([
        'ai-coaching-type',
        'cgm-device-type',
        'admin-audit-action',
    ])
})
</script>
