import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("h2", null, [
        _cache[0] || (_cache[0] = _createTextVNode(" 필라이즈 어드민 입니다.")),
        _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
        _createTextVNode(" API: " + _toDisplayString(`${_ctx.apiServerAddress}`) + " 입니다. ", 1)
      ])
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("h2", null, [
        _cache[3] || (_cache[3] = _createTextVNode("바로가기: ")),
        _createVNode(_component_router_link, { to: "/qaTool" }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("QA Tools")
          ])),
          _: 1
        })
      ])
    ])
  ], 64))
}