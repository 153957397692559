<template>
    <div class="resource--page">
        <slot name="summary" :search="reloadPage" />
        <a-page-header :title="title" />
        <filter-form
            :name="`${resourceName}Filter`"
            v-if="filterSpec"
            :countOfColumn="countOfFilterColumn"
            :filter-spec="filterSpec"
            @search="onSearchAndHook"
        />
        <div class="page-button-group">
            <a-button-group>
                <CreateFormModal
                    v-if="createSpec"
                    :title="createModalTitle || `${title} 생성`"
                    :label="`${createModalTitle || title} 등록`"
                    :uri="uri"
                    :input-spec="createSpec"
                    :rules="createRules"
                    @after-submit="onSearchAndHook"
                />
                <slot name="actions" :uri="uri" :search="reloadPage" />
            </a-button-group>
            <a-button v-if="exportable" :href="csvExportUrl">
                <template #icon>
                    <download-outlined />
                </template>
                CSV 다운로드
            </a-button>
        </div>
        <a-table
            v-bind="defaultTableProps"
            :columns="extendedColumns"
            :scroll="scroll"
            :row-selection="rowSelection"
            @change="handlePage"
        >
            <template #customFilterDropdown="{ column }">
                <div style="padding: 8px">
                    <InputMapper
                        :input-spec="
                            (filterSpec &&
                                filterSpec[column.alias || column.dataIndex]) ||
                            null
                        "
                        hide-label
                        @press-enter="onSearchAndHook"
                    />
                    <a-row>
                        <a-col :span="14" />
                        <a-col :span="10">
                            <a-button
                                type="primary"
                                size="small"
                                @click="onSearchAndHook"
                            >
                                검색
                            </a-button>
                        </a-col>
                    </a-row>
                </div>
            </template>

            <!-- 커스텀 컬럼 렌더 정의 -->

            <template #nutrients="nutrients">
                <span v-for="nu in nutrients" :key="nu.nutrientName">
                    <a-tag color="blue">
                        {{ `${nu.nutrientName} ${nu.amount}${nu.unit}` }}
                    </a-tag>
                    <a-tag v-if="nu.nutrientName != nu.normedNutrientName">
                        {{
                            `${nu.normedNutrientName} ${nu.normedAmount}${nu.normedUnit}`
                        }}
                    </a-tag>
                </span>
            </template>
            <template #bodyCell="{ column, text, record }">
                <a-descriptions
                    v-if="column.type === 'data'"
                    layout="vertical"
                    :column="1"
                >
                    <a-descriptions-item
                        v-if="record.data.foodId"
                        label="식품 ID"
                    >
                        <span style="margin-left: 10px">
                            {{ record.data.foodId }}
                        </span>
                    </a-descriptions-item>
                    <a-descriptions-item
                        v-if="record.data.foodName"
                        label="식품명"
                    >
                        <span style="margin-left: 10px">
                            {{ record.data.foodName }}
                        </span>
                    </a-descriptions-item>
                    <a-descriptions-item
                        v-if="record.data.foodNoServing"
                        label="제공횟수"
                    >
                        <span style="margin-left: 10px">
                            {{ record.data.foodNoServing }}
                        </span>
                    </a-descriptions-item>
                    <a-descriptions-item
                        v-if="record.data.calorie"
                        label="칼로리"
                    >
                        <span style="margin-left: 10px">
                            {{ record.data.calorie }}
                        </span>
                    </a-descriptions-item>
                    <a-descriptions-item
                        v-if="record.data.productId"
                        label="제품 ID"
                    >
                        <span style="margin-left: 10px">
                            {{ record.data.productId }}
                        </span>
                    </a-descriptions-item>
                    <a-descriptions-item
                        v-if="record.data.productIds"
                        label="제품 ID"
                    >
                        <span style="margin-left: 10px">
                            {{ record.data.productIds.join(', ') }}
                        </span>
                    </a-descriptions-item>
                    <a-descriptions-item v-if="record.data.name" label="대상">
                        <span style="margin-left: 10px">
                            {{ record.data.name }}
                        </span>
                    </a-descriptions-item>
                    <a-descriptions-item
                        v-if="record.data.productName"
                        label="제품명"
                    >
                        <span style="margin-left: 10px">
                            {{ record.data.productName }}
                        </span>
                    </a-descriptions-item>
                    <a-descriptions-item
                        v-if="record.data.brandName"
                        label="브랜드명"
                    >
                        <span style="margin-left: 10px">
                            {{ record.data.brandName }}
                        </span>
                    </a-descriptions-item>
                    <a-descriptions-item v-if="record.data.url" label="링크">
                        <a
                            :href="sanitizeUrl(record.data.url)"
                            target="_blank"
                            style="margin-left: 10px"
                        >
                            {{ record.data.url }}
                        </a>
                    </a-descriptions-item>
                    <a-descriptions-item
                        v-if="record.data.imageUrls"
                        label="이미지"
                    >
                        <!--                        <a-->
                        <!--                            :href="sanitizeUrl(record.data.productImageUrls)"-->
                        <!--                            target="_blank"-->
                        <!--                            style="margin-left: 10px"-->
                        <!--                        >-->
                        <a-image-preview-group>
                            <a-image
                                v-for="(image, index) in record.data.imageUrls"
                                :width="200"
                                :src="image"
                                :key="index"
                            />
                        </a-image-preview-group>
                        <!--                        </a>-->
                    </a-descriptions-item>
                    <a-descriptions-item v-if="record.data.files" label="파일">
                        <a-image-preview-group>
                            <a-image
                                v-for="(image, index) in record.data.files"
                                :width="200"
                                :src="image"
                                :key="index"
                            />
                        </a-image-preview-group>
                    </a-descriptions-item>
                    <a-descriptions-item v-if="record.data.text" label="주석">
                        <span style="margin-left: 10px">
                            {{ record.data.text }}
                        </span>
                    </a-descriptions-item>
                    <a-descriptions-item
                        v-if="record.data.satisfaction != null"
                        label="만족여부"
                    >
                        <span
                            v-if="record.data.satisfaction === true"
                            style="margin-left: 10px; color: green"
                        >
                            <b>{{ record.data.satisfaction }}</b>
                        </span>
                        <span
                            v-else-if="record.data.satisfaction === false"
                            style="margin-left: 10px; color: red"
                        >
                            <b>{{ record.data.satisfaction }}</b>
                        </span>
                    </a-descriptions-item>
                    <a-descriptions-item
                        v-if="record.data.realName"
                        label="실명"
                    >
                        <span style="margin-left: 10px">
                            {{ record.data.realName }}
                        </span>
                    </a-descriptions-item>
                    <a-descriptions-item v-if="record.data.os" label="OS">
                        <span style="margin-left: 10px">
                            {{ record.data.os }}
                        </span>
                    </a-descriptions-item>
                    <a-descriptions-item
                        v-if="record.data.buildNumber"
                        label="빌드번호"
                    >
                        <span style="margin-left: 10px">
                            {{ record.data.buildNumber }}
                        </span>
                    </a-descriptions-item>
                    <a-descriptions-item
                        v-if="record.data.nutrientName"
                        label="영양소명"
                    >
                        <span style="margin-left: 10px">
                            {{ record.data.nutrientName }}
                        </span>
                    </a-descriptions-item>
                    <a-descriptions-item
                        v-if="record.data.nutrientId"
                        label="영양소 ID"
                    >
                        <span style="margin-left: 10px">
                            {{ record.data.nutrientId }}
                        </span>
                    </a-descriptions-item>
                    <a-descriptions-item
                        v-if="record.data.content"
                        label="내용"
                    >
                        <span style="margin-left: 10px">
                            {{ record.data.content }}
                        </span>
                    </a-descriptions-item>
                    <a-descriptions-item
                        v-if="record.data.contents"
                        label="내용"
                    >
                        <span style="margin-left: 10px">
                            {{ record.data.contents }}
                        </span>
                    </a-descriptions-item>
                    <a-descriptions-item
                        v-if="record.data.userId"
                        label="사용자ID"
                    >
                        <span style="margin-left: 10px">
                            {{ record.data.userId }}
                        </span>
                    </a-descriptions-item>
                    <a-descriptions-item
                        v-if="record.data.email"
                        label="e-mail"
                    >
                        <span style="margin-left: 10px">
                            {{ record.data.email }}
                        </span>
                    </a-descriptions-item>
                    <div>
                        <a-descriptions
                            v-for="item of record.data.reasons"
                            layout="vertical"
                            :key="item.id"
                            :column="1"
                        >
                            <a-descriptions-item
                                v-if="item.feature"
                                label="특이사항"
                            >
                                {{ item }}
                                <span style="margin-left: 10px">
                                    {{ item.feature }}
                                </span>
                            </a-descriptions-item>
                            <a-descriptions-item
                                v-if="item.comment"
                                label="주석"
                            >
                                <span style="margin-left: 10px">
                                    {{ item.comment }}
                                </span>
                            </a-descriptions-item>
                            <a-descriptions-item
                                v-if="item.reason"
                                label="사유"
                            >
                                <span style="margin-left: 10px">
                                    {{ item.reason }}
                                </span>
                            </a-descriptions-item>
                        </a-descriptions>
                    </div>
                </a-descriptions>
                <user-mention
                    v-if="column.type === 'assignee'"
                    :names="record.assigneeNames"
                    :resource-id="record.id"
                />
                <div v-if="column.type === 'userFeedbackResponse'">
                    <span v-if="record.replied">{{ '전송 완료' }}</span>
                    <user-feedback-response-modal
                        v-else
                        :title="`${getUserFeedbackTopic(record.topic)} 답변`"
                        label="답변하기"
                        :topic="record.topic"
                        :user-name="record.userName"
                        :data="record.data"
                        :resource-id="record.id"
                        :uri="''"
                        @after-submit="reloadPage"
                    />
                </div>
                <img
                    v-if="
                        column.type === 'img' && text && !text.endsWith('.json')
                    "
                    :src="text && text"
                    style="width: 100px; height: 100px"
                />
                <Vue3Lottie
                    v-else-if="
                        column.type === 'img' && text && text.endsWith('.json')
                    "
                    :animation-link="text"
                    :width="100"
                    :height="100"
                />
                <a-image-preview-group v-if="column.type === 'reviewImage'">
                    <a-image
                        v-for="(item, index) in record.reviewImage"
                        :key="index"
                        :src="item.url"
                        style="width: 100px; height: 100px"
                    />
                </a-image-preview-group>
                <a
                    v-if="column.type === 'link'"
                    :href="sanitizeUrl(text)"
                    target="_blank"
                >
                    링크
                    <link-outlined />
                </a>
                <span v-if="column.type === 'tags'">
                    <a-tag v-for="tag in text" :key="tag" color="blue">{{
                        tag
                    }}</a-tag>
                </span>
                <div v-if="column.type === 'delete' && deletable">
                    <delete-popconfirm
                        v-if="column.type === 'delete' && deletable"
                        :title="`${record[primaryKey]}를 삭제하시겠습니까?`"
                        :uri="`${uri}/${record[primaryKey]}`"
                        :disabled="rowDeletable && !rowDeletable(record)"
                        @after-delete="reloadPage"
                    />
                </div>
                <div v-if="column.type === 'copy'">
                    <copy-pop-confirm
                        v-if="column.type === 'copy'"
                        :title="`${record.name}를 복사 하시겠습니까?`"
                        :uri="`${uri}/copy`"
                        :record="record"
                        @after-copy="reloadPage"
                    />
                </div>
                <div v-if="column.type === 'send-crm-seg-sms'">
                    <send-crm-seg-modal
                        :value-props="record"
                        :type-props="'SMS'"
                        :label-props="'문자'"
                    />
                </div>
                <div v-if="column.type === 'send-crm-seg-bizm'">
                    <send-crm-seg-modal
                        :value-props="record"
                        :type-props="'KAKAO_TALK'"
                        :label-props="'알림톡'"
                    />
                </div>
                <div v-if="column.type === 'send-crm-sms'">
                    <send-one-crm-modal
                        :event-user-props="record"
                        :type-props="'SMS'"
                        :label-props="'문자'"
                    />
                </div>
                <div v-if="column.type === 'send-crm-bizm'">
                    <send-one-crm-modal
                        :event-user-props="record"
                        :type-props="'KAKAO_TALK'"
                        :label-props="'알림톡'"
                    />
                </div>
                <a-space v-if="column.type === 'edit'">
                    <a-button
                        v-if="hasEditPage"
                        class="modal__button"
                        @click="showEditForm(record[primaryKey])"
                    >
                        <template #icon>
                            <edit-outlined />
                        </template>
                    </a-button>
                    <UpdateFormModal
                        v-else
                        :title="updateModalTitle || `${title} 수정`"
                        :resource-id="record[primaryKey]"
                        :uri="uri"
                        :input-spec="updateSpec!!"
                        :rules="updateRules"
                        @after-submit="reloadPage"
                    >
                        <template #header="data">
                            <slot name="updateModalHeader" v-bind="data" />
                        </template>
                        <template #footer="data">
                            <slot name="updateModalFooter" v-bind="data" />
                        </template>
                    </UpdateFormModal>
                    <slot
                        name="edit"
                        :uri="uri"
                        :search="reloadPage"
                        :record="record"
                    />
                </a-space>
                <a-space v-if="column.type === 'contentEdit'">
                    <a-button
                        class="modal__button"
                        v-if="hasEditPage"
                        @click="showEditForm(record[primaryKey])"
                    >
                        <template #icon>
                            <edit-outlined />
                        </template>
                    </a-button>
                    <UpdateContentFormModal
                        v-else
                        :title="updateModalTitle || `컨텐츠 수정`"
                        :resource-id="record[primaryKey]"
                        :uri="uri"
                        :input-spec="updateContentSpec!!"
                        @after-submit="reloadPage"
                    >
                        <template #header="data">
                            <slot name="updateModalHeader" v-bind="data" />
                        </template>
                        <template #footer="data">
                            <slot name="updateModalFooter" v-bind="data" />
                        </template>
                    </UpdateContentFormModal>
                    <slot
                        name="edit"
                        :uri="uri"
                        :search="reloadPage"
                        :record="record"
                    />
                </a-space>
                <a-space v-if="column.type === 'detail'">
                    <DetailModal
                        :resource-id="record[primaryKey]"
                        :uri="uri"
                        :form-spec="{}"
                    >
                        <template #body="{ resourceId, record, fetchResource }">
                            <slot
                                name="detail"
                                :resource-id="resourceId"
                                :record="record"
                                :search="reloadPage"
                                :fetch-resource="fetchResource"
                            />
                        </template>
                    </DetailModal>
                </a-space>
                <a-space v-if="column.type == 'action'">
                    <ActionModal
                        :title="actionModalTitle"
                        :resource-id="record[primaryKey]"
                        :uri="actionUri!!"
                        :input-spec="actionSpec!!"
                        :rules="actionRules"
                        :record="record"
                        :disabled="actionModalDisabled(record)"
                        @after-submit="reloadPage"
                        @show-modal="
                            (data: any) =>
                                $emit('show-action-modal', data)
                        "
                    >
                        <template #header="data">
                            <slot name="actionModalHeader" v-bind="data" />
                        </template>
                        <template #footer="data">
                            <slot name="actionModalFooter" v-bind="data" />
                        </template>
                        <template #icon>
                            <slot name="actionModalIcon" />
                        </template>
                        <template #beforeButton="data">
                            <slot
                                name="actionModalBeforeButton"
                                v-bind="data"
                            />
                        </template>
                    </ActionModal>
                    <slot
                        name="action"
                        :uri="uri"
                        :search="reloadPage"
                        :record="record"
                    />
                </a-space>
                <span v-if="column.type === 'ad-properties-obj'">
                    <a-tag
                        :key="index"
                        v-for="(property, key, index) in record.properties"
                    >
                        {{ key }}({{ property }})
                    </a-tag>
                </span>
                <span v-if="column.type === 'ad-instance-event-url'">
                    <a-tooltip placement="top" :color="blue">
                        <template #title>
                            <a
                                :href="
                                    sanitizeUrl(
                                        record?.eventHandler?.url ||
                                            'https://www.pillyze.com'
                                    )
                                "
                                target="_blank"
                            >
                                {{ record?.eventHandlerUrl }}
                            </a>
                        </template>
                        <span>{{ record?.eventHandlerType }}</span>
                    </a-tooltip>
                </span>
                <div v-if="column.type === 'ad-layout-supported'">
                    <span
                        :key="layout"
                        v-for="layout in record.supportedLayouts"
                    >
                        <a-tag>[{{ layout.id }}] {{ layout.name }}</a-tag>
                    </span>
                </div>
                <div
                    v-if="column.type === 'ad-instances'"
                    style="display: flex; flex-direction: column"
                >
                    <span
                        v-for="instance in record.instances"
                        :key="instance.id"
                    >
                        [{{ instance.id }}] {{ instance.name }}
                    </span>
                </div>
                <div v-if="column.type === 'ad-preview'">
                    <ad-preview :instance="record" />
                </div>
                <div v-if="column.type === 'ad-target-user'">
                    <div>
                        {{ record.targetUser.segId }}
                    </div>
                </div>
                <div v-if="column.type === 'ad-repeat'">
                    <ad-repeat :instance="record" />
                </div>
                <a-space v-if="column.type == 'hasImage'">
                    <picture-outlined
                        v-if="text"
                        style="font-size: 30px; color: green"
                    />
                    <close-outlined
                        v-else
                        style="font-size: 30px; color: red"
                    />
                </a-space>
                <a-space v-if="column.type == 'hasImageSize'">
                    <picture-outlined
                        v-if="record.imageList.length > 0"
                        style="font-size: 30px; color: green"
                    />
                    <close-outlined
                        v-else
                        style="font-size: 30px; color: red"
                    />
                </a-space>
                <a-space
                    v-if="column.type == 'text-ellipsis'"
                    style="
                        white-space: nowrap;
                        overflow: scroll;
                        text-overflow: ellipsis;
                        max-width: 100%;
                    "
                >
                    <a-span class="ellipsis">
                        {{ record[column.dataIndex] }}
                    </a-span>
                </a-space>
                <a-space v-if="column.type == 'customAction'">
                    <slot name="customAction" :record="record" />
                </a-space>
                <a-space v-if="column.type == 'owner' && record.owner">
                    <a-avatar
                        v-if="record.owner?.profileImageUrl"
                        :src="record.owner?.profileImageUrl"
                    />
                    <a-avatar v-else-if="record.owner.profileName">
                        <template #icon>
                            <UserOutlined />
                        </template>
                    </a-avatar>
                    {{ record.owner?.profileName }}
                </a-space>
            </template>
        </a-table>
        <router-view />
    </div>
</template>

<style lang="scss">
.resource--page {
    padding: 50px;

    .page-button-group {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
</style>

<script lang="ts">
import TableSupport from '@/views/tableSupport'
import InputMapper from '@/components/InputMapper.vue'
import FilterForm from '@/components/FilterForm.vue'
import CreateFormModal from '@/components/modal/CreateFormModal.vue'
import UpdateFormModal from '@/components/modal/UpdateFormModal.vue'
import UpdateContentFormModal from '@/components/modal/UpdateContentFormModal.vue'
import DetailModal from '@/components/modal/DetailModal.vue'
import DeletePopconfirm from '@/components/DeletePopconfirm.vue'
import CopyPopConfirm from '@/components/CopyPopConfirm.vue'
import { sanitizeUrl } from '@braintree/sanitize-url'
import UserMention from '@/components/UserMention.vue'
import UserFeedbackResponseModal from '@/components/modal/UserFeedbackResponseModal.vue'
import ActionModal from '@/components/modal/ActionModal.vue'
import { formatEnum } from '@/util/formmater'
import { defineComponent, ref } from 'vue'
import {
    PictureOutlined,
    CloseOutlined,
    LinkOutlined,
    DownloadOutlined,
    EditOutlined,
    UserOutlined,
} from '@ant-design/icons-vue'
import AdPreview from '@/views/ad/AdPreview.vue'
import AdRepeat from '@/views/ad/AdRepeat.vue'
import { Vue3Lottie } from 'vue3-lottie'
import SendCrmSegModal from '@/components/modal/SendCrmSegModal.vue'
import SendOneCrmModal from '@/components/modal/SendOneCrmModal.vue'
import { useRouter } from 'vue-router'

export default defineComponent({
    name: 'ResourceTable',
    mixins: [TableSupport],
    components: {
        SendOneCrmModal,
        SendCrmSegModal,
        AdPreview,
        AdRepeat,
        FilterForm,
        CreateFormModal,
        UpdateFormModal,
        UpdateContentFormModal,
        ActionModal,
        DetailModal,
        CopyPopConfirm,
        DeletePopconfirm,
        InputMapper,
        UserMention,
        UserFeedbackResponseModal,
        PictureOutlined,
        CloseOutlined,
        LinkOutlined,
        DownloadOutlined,
        EditOutlined,
        UserOutlined,
        Vue3Lottie,
    },
    emits: ['after-search', 'show-action-modal', 'after-show-update'],
    props: {
        primaryKey: {
            type: String,
            default: 'id',
        },
        resourceName: {
            type: String,
        },
        title: {
            type: String,
        },
        filterSpec: {
            type: Object,
        },
        createSpec: {
            type: Object,
        },
        updateSpec: {
            type: Object,
        },
        updateContentSpec: {
            type: Object,
        },
        actionSpec: {
            type: Object,
        },
        actionUri: {
            type: String,
        },
        columns: {
            type: Array,
            default: () => [],
        },
        exportable: {
            type: Boolean,
            default: false,
        },
        deletable: {
            type: Boolean,
            default: false,
        },
        rowDeletable: {
            type: Function,
            default: () => true,
        },
        scroll: {
            type: Object,
            default: () => ({ y: 650 }),
        },
        pagination: {
            type: Object,
        },
        rowSelection: {
            type: Object,
        },
        createRules: {
            type: Object,
        },
        updateRules: {
            type: Object,
        },
        actionRules: {
            type: Object,
        },
        loadOnMount: {
            type: Boolean,
            default: false,
        },
        editColumnWidth: {
            type: Number,
            default: 66,
        },
        actionColumnWidth: {
            type: Number,
            default: 66,
        },
        updateColumnWidth: {
            type: String,
        },
        actionModalTitle: {
            type: String,
        },
        createModalTitle: {
            type: String,
        },
        updateModalTitle: {
            type: String,
        },
        countOfFilterColumn: {
            type: Number,
        },
        actionModalDisabled: {
            type: Function,
            default: () => false,
        },
        hasEditPage: {
            type: Boolean,
            default: false,
        },
        pageable: {
            type: Boolean,
            default: true,
        },
        method: {
            type: String,
            default: 'GET',
        },
    },

    setup(props) {
        const createFormVisible = ref(false)
        const router = useRouter()
        const showEditForm = (id: number) => {
            router.push(`/${props.resourceName}/edit/${id}`)
        }
        return { createFormVisible, showEditForm }
    },

    computed: {
        extendedColumns() {
            const extended = [...this.columns]

            if (this.actionSpec != null) {
                extended.push({
                    title: this.actionModalTitle,
                    dataIndex: 'action',
                    width: this.actionColumnWidth,
                    fixed: 'right',
                    align: 'center',
                    type: 'action',
                })
            }

            if (this.updateSpec != null || this.hasEditPage === true) {
                extended.push({
                    title: '수정',
                    dataIndex: 'edit',
                    width: this.editColumnWidth,
                    fixed: 'right',
                    align: 'center',
                    type: 'edit',
                })
            }

            if (this.updateContentSpec != null) {
                extended.push({
                    title: '컨텐츠 수정',
                    dataIndex: 'contentEdit',
                    width: this.editColumnWidth,
                    fixed: 'right',
                    align: 'center',
                    type: 'contentEdit',
                })
            }

            if (this.deletable) {
                extended.push({
                    title: '삭제',
                    dataIndex: 'delete',
                    width: 65,
                    fixed: 'right',
                    align: 'center',
                    type: 'delete',
                })
            }

            return extended
        },
    },

    methods: {
        parseJson(text: string) {
            try {
                return JSON.parse(text)
            } catch (error) {
                return text
            }
        },
        getUserFeedbackTopic(value: string) {
            return formatEnum('user-feedback-topic')({ text: value })
        },
        sanitizeUrl(url: string) {
            if (!url?.includes('https://')) {
                return sanitizeUrl(`https://${url}`)
            }
            return sanitizeUrl(url)
        },
        onSearchAndHook() {
            this.onSearch()
            this.$emit('after-search')
        },
        reloadPage() {
            this.reload()
        },
        afterShowUpdate() {
            this.$emit('after-show-update')
        },
    },

    mounted() {
        if (this.loadOnMount === true) {
            this.onSearch(() => this.$emit('after-search'))
        }
    },
})
</script>
