<template>
    <div>
        <a-form>
            <a-row :gutter="24">
                <template
                    v-for="(inputItems, rowIndex) in gridKeys"
                    :key="rowIndex"
                >
                    <a-col
                        v-for="(filterKey, columnIndex) in inputItems"
                        :key="columnIndex"
                        :span="24 / countOfColumn"
                        v-show="rowIndex < showInputCount()"
                        style="overflow: auto"
                    >
                        <InputMapper
                            :input-spec="formState[name].spec[filterKey]"
                            @press-enter="$emit('search')"
                        />
                    </a-col>
                </template>
            </a-row>
        </a-form>
        <a-row>
            <a-col
                :span="24"
                :style="{ textAlign: 'right', paddingBottom: '45px' }"
            >
                <a-button
                    v-if="!inputOnly"
                    type="primary"
                    html-type="submit"
                    @click="$emit('search')"
                >
                    조회
                </a-button>
                <a-button
                    :style="{ marginLeft: '8px' }"
                    @click="resetForm(name)"
                >
                    초기화
                </a-button>
                <span v-if="inputCount > 6">
                    <a
                        v-if="expand == true"
                        :style="{ marginLeft: '8px', fontSize: '12px' }"
                        @click="toggleExpand"
                    >
                        숨기기 <up-outlined />
                    </a>
                    <a
                        v-else
                        :style="{ marginLeft: '8px', fontSize: '12px' }"
                        @click="toggleExpand"
                    >
                        더보기 <down-outlined />
                    </a>
                </span>
            </a-col>
        </a-row>
    </div>
</template>

<script lang="ts" setup>
import InputMapper from '@/components/InputMapper.vue'
import { computed, defineEmits, defineProps, onMounted, ref } from 'vue'
import { DownOutlined, UpOutlined } from '@ant-design/icons-vue'
import { useFormStore } from '@/store/form'

const props = defineProps({
    name: {
        type: String,
        required: true,
        default: 'filter',
    },
    filterSpec: {
        type: Object,
        required: true,
    },
    countOfColumn: {
        type: Number,
        default: 3,
    },
    inputOnly: {
        type: Boolean,
        default: false,
    },
})

defineEmits(['search'])

const { formState, resetForm, getRecordKeys, setFormSpec } = useFormStore()

const expand = ref(false)

const inputCount = computed(() => {
    return getRecordKeys(props.name).length
})

const gridKeys = computed(() => {
    const sliceCount = Math.ceil(inputCount.value / props.countOfColumn)
    const sliced = []

    for (let i = 0; i < sliceCount; i++) {
        sliced.push(
            getRecordKeys(props.name).slice(
                i * props.countOfColumn,
                (i + 1) * props.countOfColumn
            )
        )
    }
    return sliced
})

const showInputCount = () => {
    return expand.value === true
        ? Math.ceil(inputCount.value / props.countOfColumn)
        : 3
}

const toggleExpand = () => {
    expand.value = !expand.value
}

onMounted(() => {
    if (
        props.name === 'filter' ||
        (props.filterSpec && !formState[props.name])
    ) {
        setFormSpec(props.name, props.filterSpec)
    }
})
</script>
