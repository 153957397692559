import { SelectOption, useEnumTypeStore } from '@/store/enumType'
import Moment from 'moment'
import { FormatOptions, numToKorean } from 'num-to-korean'
import { format, isToday } from 'date-fns'
import { ko } from 'date-fns/locale'

type CellProps = {
    index?: number
    text?: any
    record?: Record<any, any>
}

export function formatKoreanTime(date: Date) {
    if (isToday(date)) {
        // 오늘 날짜인 경우 '오후 12:00' 형식으로 포맷
        return format(date, 'a h:mm', { locale: ko })
    } else {
        return format(date, 'yy년 MM월 dd일 a h:mm', { locale: ko })
    }
}

export const formatLocalDateTime = (value: CellProps | string) => {
    if (typeof value === 'string') {
        return (
            (value && Moment(value as string).format('YYYY-MM-DD HH:mm:ss')) ||
            ''
        )
    }

    const cellProps = value as CellProps
    return (
        (cellProps?.text &&
            Moment(cellProps.text).format('YYYY-MM-DD HH:mm:ss')) ||
        ''
    )
}

export const formatWeekOfDay = (value: CellProps | string) => {
    if (typeof value === 'string') {
        return format(value, 'eeee', { locale: ko })
    }

    if (value == null || value.text == null) {
        return ''
    }

    return format(value.text, 'eeee', { locale: ko })
}

export const formatLocalDate = (value: CellProps | string) => {
    if (typeof value === 'string') {
        return (value && Moment(value as string).format('YYYY-MM-DD')) || ''
    }

    const cellProps = value as CellProps
    return (cellProps.text && Moment(cellProps.text).format('YYYY-MM-DD')) || ''
}

export const formatBoolean = (row: Record<any, any>) => {
    const value = row.value
    if (value === false || value === 'false') return 'X'
    if (value === true || value === 'true') return 'O'
    return ''
}

export const formatEnum = (typeName: string) => (value: CellProps) => {
    const enumTypeStore = useEnumTypeStore()
    return enumTypeStore
        .getEnumType(typeName)
        ?.filter((it: SelectOption) => it.value == value.text)[0]?.label
}

export const formatEnumList = (typeName: string) => (value: CellProps) => {
    const enumTypeStore = useEnumTypeStore()
    return value.text
        .map(
            (text: string) =>
                enumTypeStore
                    .getEnumType(typeName)
                    ?.filter((it: SelectOption) => it.value == text)[0]?.label
        )
        .join(', ')
}

export const formatLocaleNumber = (value: CellProps) => {
    const n = Number(value.text)
    if (isNaN(n)) {
        return '0'
    }

    if (value.text == 9999999999999) {
        return '무한대'
    }
    return n.toLocaleString()
}

export const formatKoreanNumber = (value: CellProps) => {
    if (value.text != null) {
        if (value.text == 9999999999999) {
            return '무한대'
        }
        return numToKorean(value.text, FormatOptions.MIXED)
    }
    return ''
}
