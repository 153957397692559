import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "resource--page" }
const _hoisted_2 = { class: "page-button-group" }
const _hoisted_3 = { style: {"padding":"8px"} }
const _hoisted_4 = { style: {"margin-left":"10px"} }
const _hoisted_5 = { style: {"margin-left":"10px"} }
const _hoisted_6 = { style: {"margin-left":"10px"} }
const _hoisted_7 = { style: {"margin-left":"10px"} }
const _hoisted_8 = { style: {"margin-left":"10px"} }
const _hoisted_9 = { style: {"margin-left":"10px"} }
const _hoisted_10 = { style: {"margin-left":"10px"} }
const _hoisted_11 = { style: {"margin-left":"10px"} }
const _hoisted_12 = { style: {"margin-left":"10px"} }
const _hoisted_13 = ["href"]
const _hoisted_14 = { style: {"margin-left":"10px"} }
const _hoisted_15 = {
  key: 0,
  style: {"margin-left":"10px","color":"green"}
}
const _hoisted_16 = {
  key: 1,
  style: {"margin-left":"10px","color":"red"}
}
const _hoisted_17 = { style: {"margin-left":"10px"} }
const _hoisted_18 = { style: {"margin-left":"10px"} }
const _hoisted_19 = { style: {"margin-left":"10px"} }
const _hoisted_20 = { style: {"margin-left":"10px"} }
const _hoisted_21 = { style: {"margin-left":"10px"} }
const _hoisted_22 = { style: {"margin-left":"10px"} }
const _hoisted_23 = { style: {"margin-left":"10px"} }
const _hoisted_24 = { style: {"margin-left":"10px"} }
const _hoisted_25 = { style: {"margin-left":"10px"} }
const _hoisted_26 = { style: {"margin-left":"10px"} }
const _hoisted_27 = { style: {"margin-left":"10px"} }
const _hoisted_28 = { style: {"margin-left":"10px"} }
const _hoisted_29 = { key: 2 }
const _hoisted_30 = { key: 0 }
const _hoisted_31 = ["src"]
const _hoisted_32 = ["href"]
const _hoisted_33 = { key: 7 }
const _hoisted_34 = { key: 8 }
const _hoisted_35 = { key: 9 }
const _hoisted_36 = { key: 10 }
const _hoisted_37 = { key: 11 }
const _hoisted_38 = { key: 12 }
const _hoisted_39 = { key: 13 }
const _hoisted_40 = { key: 18 }
const _hoisted_41 = { key: 19 }
const _hoisted_42 = ["href"]
const _hoisted_43 = { key: 20 }
const _hoisted_44 = {
  key: 21,
  style: {"display":"flex","flex-direction":"column"}
}
const _hoisted_45 = { key: 22 }
const _hoisted_46 = { key: 23 }
const _hoisted_47 = { key: 24 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_page_header = _resolveComponent("a-page-header")!
  const _component_filter_form = _resolveComponent("filter-form")!
  const _component_CreateFormModal = _resolveComponent("CreateFormModal")!
  const _component_a_button_group = _resolveComponent("a-button-group")!
  const _component_download_outlined = _resolveComponent("download-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_InputMapper = _resolveComponent("InputMapper")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_descriptions_item = _resolveComponent("a-descriptions-item")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_image_preview_group = _resolveComponent("a-image-preview-group")!
  const _component_a_descriptions = _resolveComponent("a-descriptions")!
  const _component_user_mention = _resolveComponent("user-mention")!
  const _component_user_feedback_response_modal = _resolveComponent("user-feedback-response-modal")!
  const _component_Vue3Lottie = _resolveComponent("Vue3Lottie")!
  const _component_link_outlined = _resolveComponent("link-outlined")!
  const _component_delete_popconfirm = _resolveComponent("delete-popconfirm")!
  const _component_copy_pop_confirm = _resolveComponent("copy-pop-confirm")!
  const _component_send_crm_seg_modal = _resolveComponent("send-crm-seg-modal")!
  const _component_send_one_crm_modal = _resolveComponent("send-one-crm-modal")!
  const _component_edit_outlined = _resolveComponent("edit-outlined")!
  const _component_UpdateFormModal = _resolveComponent("UpdateFormModal")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_UpdateContentFormModal = _resolveComponent("UpdateContentFormModal")!
  const _component_DetailModal = _resolveComponent("DetailModal")!
  const _component_ActionModal = _resolveComponent("ActionModal")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_ad_preview = _resolveComponent("ad-preview")!
  const _component_ad_repeat = _resolveComponent("ad-repeat")!
  const _component_picture_outlined = _resolveComponent("picture-outlined")!
  const _component_close_outlined = _resolveComponent("close-outlined")!
  const _component_a_span = _resolveComponent("a-span")!
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_UserOutlined = _resolveComponent("UserOutlined")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "summary", { search: _ctx.reloadPage }),
    _createVNode(_component_a_page_header, { title: _ctx.title }, null, 8, ["title"]),
    (_ctx.filterSpec)
      ? (_openBlock(), _createBlock(_component_filter_form, {
          key: 0,
          name: `${_ctx.resourceName}Filter`,
          countOfColumn: _ctx.countOfFilterColumn,
          "filter-spec": _ctx.filterSpec,
          onSearch: _ctx.onSearchAndHook
        }, null, 8, ["name", "countOfColumn", "filter-spec", "onSearch"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_button_group, null, {
        default: _withCtx(() => [
          (_ctx.createSpec)
            ? (_openBlock(), _createBlock(_component_CreateFormModal, {
                key: 0,
                title: _ctx.createModalTitle || `${_ctx.title} 생성`,
                label: `${_ctx.createModalTitle || _ctx.title} 등록`,
                uri: _ctx.uri,
                "input-spec": _ctx.createSpec,
                rules: _ctx.createRules,
                onAfterSubmit: _ctx.onSearchAndHook
              }, null, 8, ["title", "label", "uri", "input-spec", "rules", "onAfterSubmit"]))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "actions", {
            uri: _ctx.uri,
            search: _ctx.reloadPage
          })
        ]),
        _: 3
      }),
      (_ctx.exportable)
        ? (_openBlock(), _createBlock(_component_a_button, {
            key: 0,
            href: _ctx.csvExportUrl
          }, {
            icon: _withCtx(() => [
              _createVNode(_component_download_outlined)
            ]),
            default: _withCtx(() => [
              _cache[1] || (_cache[1] = _createTextVNode(" CSV 다운로드 "))
            ]),
            _: 1
          }, 8, ["href"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_a_table, _mergeProps(_ctx.defaultTableProps, {
      columns: _ctx.extendedColumns,
      scroll: _ctx.scroll,
      "row-selection": _ctx.rowSelection,
      onChange: _ctx.handlePage
    }), {
      customFilterDropdown: _withCtx(({ column }) => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_InputMapper, {
            "input-spec": 
                            (_ctx.filterSpec &&
                                _ctx.filterSpec[column.alias || column.dataIndex]) ||
                            null
                        ,
            "hide-label": "",
            onPressEnter: _ctx.onSearchAndHook
          }, null, 8, ["input-spec", "onPressEnter"]),
          _createVNode(_component_a_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 14 }),
              _createVNode(_component_a_col, { span: 10 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_button, {
                    type: "primary",
                    size: "small",
                    onClick: _ctx.onSearchAndHook
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode(" 검색 ")
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ])
      ]),
      nutrients: _withCtx((nutrients) => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(nutrients, (nu) => {
          return (_openBlock(), _createElementBlock("span", {
            key: nu.nutrientName
          }, [
            _createVNode(_component_a_tag, { color: "blue" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(`${nu.nutrientName} ${nu.amount}${nu.unit}`), 1)
              ]),
              _: 2
            }, 1024),
            (nu.nutrientName != nu.normedNutrientName)
              ? (_openBlock(), _createBlock(_component_a_tag, { key: 0 }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(`${nu.normedNutrientName} ${nu.normedAmount}${nu.normedUnit}`), 1)
                  ]),
                  _: 2
                }, 1024))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ]),
      bodyCell: _withCtx(({ column, text, record }) => [
        (column.type === 'data')
          ? (_openBlock(), _createBlock(_component_a_descriptions, {
              key: 0,
              layout: "vertical",
              column: 1
            }, {
              default: _withCtx(() => [
                (record.data.foodId)
                  ? (_openBlock(), _createBlock(_component_a_descriptions_item, {
                      key: 0,
                      label: "식품 ID"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_4, _toDisplayString(record.data.foodId), 1)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true),
                (record.data.foodName)
                  ? (_openBlock(), _createBlock(_component_a_descriptions_item, {
                      key: 1,
                      label: "식품명"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_5, _toDisplayString(record.data.foodName), 1)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true),
                (record.data.foodNoServing)
                  ? (_openBlock(), _createBlock(_component_a_descriptions_item, {
                      key: 2,
                      label: "제공횟수"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_6, _toDisplayString(record.data.foodNoServing), 1)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true),
                (record.data.calorie)
                  ? (_openBlock(), _createBlock(_component_a_descriptions_item, {
                      key: 3,
                      label: "칼로리"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_7, _toDisplayString(record.data.calorie), 1)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true),
                (record.data.productId)
                  ? (_openBlock(), _createBlock(_component_a_descriptions_item, {
                      key: 4,
                      label: "제품 ID"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_8, _toDisplayString(record.data.productId), 1)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true),
                (record.data.productIds)
                  ? (_openBlock(), _createBlock(_component_a_descriptions_item, {
                      key: 5,
                      label: "제품 ID"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_9, _toDisplayString(record.data.productIds.join(', ')), 1)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true),
                (record.data.name)
                  ? (_openBlock(), _createBlock(_component_a_descriptions_item, {
                      key: 6,
                      label: "대상"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_10, _toDisplayString(record.data.name), 1)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true),
                (record.data.productName)
                  ? (_openBlock(), _createBlock(_component_a_descriptions_item, {
                      key: 7,
                      label: "제품명"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_11, _toDisplayString(record.data.productName), 1)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true),
                (record.data.brandName)
                  ? (_openBlock(), _createBlock(_component_a_descriptions_item, {
                      key: 8,
                      label: "브랜드명"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_12, _toDisplayString(record.data.brandName), 1)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true),
                (record.data.url)
                  ? (_openBlock(), _createBlock(_component_a_descriptions_item, {
                      key: 9,
                      label: "링크"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("a", {
                          href: _ctx.sanitizeUrl(record.data.url),
                          target: "_blank",
                          style: {"margin-left":"10px"}
                        }, _toDisplayString(record.data.url), 9, _hoisted_13)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true),
                (record.data.imageUrls)
                  ? (_openBlock(), _createBlock(_component_a_descriptions_item, {
                      key: 10,
                      label: "이미지"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_image_preview_group, null, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.data.imageUrls, (image, index) => {
                              return (_openBlock(), _createBlock(_component_a_image, {
                                width: 200,
                                src: image,
                                key: index
                              }, null, 8, ["src"]))
                            }), 128))
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true),
                (record.data.files)
                  ? (_openBlock(), _createBlock(_component_a_descriptions_item, {
                      key: 11,
                      label: "파일"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_image_preview_group, null, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.data.files, (image, index) => {
                              return (_openBlock(), _createBlock(_component_a_image, {
                                width: 200,
                                src: image,
                                key: index
                              }, null, 8, ["src"]))
                            }), 128))
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true),
                (record.data.text)
                  ? (_openBlock(), _createBlock(_component_a_descriptions_item, {
                      key: 12,
                      label: "주석"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_14, _toDisplayString(record.data.text), 1)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true),
                (record.data.satisfaction != null)
                  ? (_openBlock(), _createBlock(_component_a_descriptions_item, {
                      key: 13,
                      label: "만족여부"
                    }, {
                      default: _withCtx(() => [
                        (record.data.satisfaction === true)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_15, [
                              _createElementVNode("b", null, _toDisplayString(record.data.satisfaction), 1)
                            ]))
                          : (record.data.satisfaction === false)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_16, [
                                _createElementVNode("b", null, _toDisplayString(record.data.satisfaction), 1)
                              ]))
                            : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true),
                (record.data.realName)
                  ? (_openBlock(), _createBlock(_component_a_descriptions_item, {
                      key: 14,
                      label: "실명"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_17, _toDisplayString(record.data.realName), 1)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true),
                (record.data.os)
                  ? (_openBlock(), _createBlock(_component_a_descriptions_item, {
                      key: 15,
                      label: "OS"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_18, _toDisplayString(record.data.os), 1)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true),
                (record.data.buildNumber)
                  ? (_openBlock(), _createBlock(_component_a_descriptions_item, {
                      key: 16,
                      label: "빌드번호"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_19, _toDisplayString(record.data.buildNumber), 1)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true),
                (record.data.nutrientName)
                  ? (_openBlock(), _createBlock(_component_a_descriptions_item, {
                      key: 17,
                      label: "영양소명"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_20, _toDisplayString(record.data.nutrientName), 1)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true),
                (record.data.nutrientId)
                  ? (_openBlock(), _createBlock(_component_a_descriptions_item, {
                      key: 18,
                      label: "영양소 ID"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_21, _toDisplayString(record.data.nutrientId), 1)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true),
                (record.data.content)
                  ? (_openBlock(), _createBlock(_component_a_descriptions_item, {
                      key: 19,
                      label: "내용"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_22, _toDisplayString(record.data.content), 1)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true),
                (record.data.contents)
                  ? (_openBlock(), _createBlock(_component_a_descriptions_item, {
                      key: 20,
                      label: "내용"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_23, _toDisplayString(record.data.contents), 1)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true),
                (record.data.userId)
                  ? (_openBlock(), _createBlock(_component_a_descriptions_item, {
                      key: 21,
                      label: "사용자ID"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_24, _toDisplayString(record.data.userId), 1)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true),
                (record.data.email)
                  ? (_openBlock(), _createBlock(_component_a_descriptions_item, {
                      key: 22,
                      label: "e-mail"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_25, _toDisplayString(record.data.email), 1)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true),
                _createElementVNode("div", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.data.reasons, (item) => {
                    return (_openBlock(), _createBlock(_component_a_descriptions, {
                      layout: "vertical",
                      key: item.id,
                      column: 1
                    }, {
                      default: _withCtx(() => [
                        (item.feature)
                          ? (_openBlock(), _createBlock(_component_a_descriptions_item, {
                              key: 0,
                              label: "특이사항"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item) + " ", 1),
                                _createElementVNode("span", _hoisted_26, _toDisplayString(item.feature), 1)
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true),
                        (item.comment)
                          ? (_openBlock(), _createBlock(_component_a_descriptions_item, {
                              key: 1,
                              label: "주석"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", _hoisted_27, _toDisplayString(item.comment), 1)
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true),
                        (item.reason)
                          ? (_openBlock(), _createBlock(_component_a_descriptions_item, {
                              key: 2,
                              label: "사유"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", _hoisted_28, _toDisplayString(item.reason), 1)
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ])
              ]),
              _: 2
            }, 1024))
          : _createCommentVNode("", true),
        (column.type === 'assignee')
          ? (_openBlock(), _createBlock(_component_user_mention, {
              key: 1,
              names: record.assigneeNames,
              "resource-id": record.id
            }, null, 8, ["names", "resource-id"]))
          : _createCommentVNode("", true),
        (column.type === 'userFeedbackResponse')
          ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
              (record.replied)
                ? (_openBlock(), _createElementBlock("span", _hoisted_30, _toDisplayString('전송 완료')))
                : (_openBlock(), _createBlock(_component_user_feedback_response_modal, {
                    key: 1,
                    title: `${_ctx.getUserFeedbackTopic(record.topic)} 답변`,
                    label: "답변하기",
                    topic: record.topic,
                    "user-name": record.userName,
                    data: record.data,
                    "resource-id": record.id,
                    uri: '',
                    onAfterSubmit: _ctx.reloadPage
                  }, null, 8, ["title", "topic", "user-name", "data", "resource-id", "onAfterSubmit"]))
            ]))
          : _createCommentVNode("", true),
        (
                        column.type === 'img' && text && !text.endsWith('.json')
                    )
          ? (_openBlock(), _createElementBlock("img", {
              key: 3,
              src: text && text,
              style: {"width":"100px","height":"100px"}
            }, null, 8, _hoisted_31))
          : (
                        column.type === 'img' && text && text.endsWith('.json')
                    )
            ? (_openBlock(), _createBlock(_component_Vue3Lottie, {
                key: 4,
                "animation-link": text,
                width: 100,
                height: 100
              }, null, 8, ["animation-link"]))
            : _createCommentVNode("", true),
        (column.type === 'reviewImage')
          ? (_openBlock(), _createBlock(_component_a_image_preview_group, { key: 5 }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.reviewImage, (item, index) => {
                  return (_openBlock(), _createBlock(_component_a_image, {
                    key: index,
                    src: item.url,
                    style: {"width":"100px","height":"100px"}
                  }, null, 8, ["src"]))
                }), 128))
              ]),
              _: 2
            }, 1024))
          : _createCommentVNode("", true),
        (column.type === 'link')
          ? (_openBlock(), _createElementBlock("a", {
              key: 6,
              href: _ctx.sanitizeUrl(text),
              target: "_blank"
            }, [
              _cache[3] || (_cache[3] = _createTextVNode(" 링크 ")),
              _createVNode(_component_link_outlined)
            ], 8, _hoisted_32))
          : _createCommentVNode("", true),
        (column.type === 'tags')
          ? (_openBlock(), _createElementBlock("span", _hoisted_33, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(text, (tag) => {
                return (_openBlock(), _createBlock(_component_a_tag, {
                  key: tag,
                  color: "blue"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(tag), 1)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (column.type === 'delete' && _ctx.deletable)
          ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
              (column.type === 'delete' && _ctx.deletable)
                ? (_openBlock(), _createBlock(_component_delete_popconfirm, {
                    key: 0,
                    title: `${record[_ctx.primaryKey]}를 삭제하시겠습니까?`,
                    uri: `${_ctx.uri}/${record[_ctx.primaryKey]}`,
                    disabled: _ctx.rowDeletable && !_ctx.rowDeletable(record),
                    onAfterDelete: _ctx.reloadPage
                  }, null, 8, ["title", "uri", "disabled", "onAfterDelete"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (column.type === 'copy')
          ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
              (column.type === 'copy')
                ? (_openBlock(), _createBlock(_component_copy_pop_confirm, {
                    key: 0,
                    title: `${record.name}를 복사 하시겠습니까?`,
                    uri: `${_ctx.uri}/copy`,
                    record: record,
                    onAfterCopy: _ctx.reloadPage
                  }, null, 8, ["title", "uri", "record", "onAfterCopy"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (column.type === 'send-crm-seg-sms')
          ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
              _createVNode(_component_send_crm_seg_modal, {
                "value-props": record,
                "type-props": 'SMS',
                "label-props": '문자'
              }, null, 8, ["value-props"])
            ]))
          : _createCommentVNode("", true),
        (column.type === 'send-crm-seg-bizm')
          ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
              _createVNode(_component_send_crm_seg_modal, {
                "value-props": record,
                "type-props": 'KAKAO_TALK',
                "label-props": '알림톡'
              }, null, 8, ["value-props"])
            ]))
          : _createCommentVNode("", true),
        (column.type === 'send-crm-sms')
          ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
              _createVNode(_component_send_one_crm_modal, {
                "event-user-props": record,
                "type-props": 'SMS',
                "label-props": '문자'
              }, null, 8, ["event-user-props"])
            ]))
          : _createCommentVNode("", true),
        (column.type === 'send-crm-bizm')
          ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
              _createVNode(_component_send_one_crm_modal, {
                "event-user-props": record,
                "type-props": 'KAKAO_TALK',
                "label-props": '알림톡'
              }, null, 8, ["event-user-props"])
            ]))
          : _createCommentVNode("", true),
        (column.type === 'edit')
          ? (_openBlock(), _createBlock(_component_a_space, { key: 14 }, {
              default: _withCtx(() => [
                (_ctx.hasEditPage)
                  ? (_openBlock(), _createBlock(_component_a_button, {
                      key: 0,
                      class: "modal__button",
                      onClick: ($event: any) => (_ctx.showEditForm(record[_ctx.primaryKey]))
                    }, {
                      icon: _withCtx(() => [
                        _createVNode(_component_edit_outlined)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  : (_openBlock(), _createBlock(_component_UpdateFormModal, {
                      key: 1,
                      title: _ctx.updateModalTitle || `${_ctx.title} 수정`,
                      "resource-id": record[_ctx.primaryKey],
                      uri: _ctx.uri,
                      "input-spec": _ctx.updateSpec!!,
                      rules: _ctx.updateRules,
                      onAfterSubmit: _ctx.reloadPage
                    }, {
                      header: _withCtx((data) => [
                        _renderSlot(_ctx.$slots, "updateModalHeader", _normalizeProps(_guardReactiveProps(data)))
                      ]),
                      footer: _withCtx((data) => [
                        _renderSlot(_ctx.$slots, "updateModalFooter", _normalizeProps(_guardReactiveProps(data)))
                      ]),
                      _: 2
                    }, 1032, ["title", "resource-id", "uri", "input-spec", "rules", "onAfterSubmit"])),
                _renderSlot(_ctx.$slots, "edit", {
                  uri: _ctx.uri,
                  search: _ctx.reloadPage,
                  record: record
                })
              ]),
              _: 2
            }, 1024))
          : _createCommentVNode("", true),
        (column.type === 'contentEdit')
          ? (_openBlock(), _createBlock(_component_a_space, { key: 15 }, {
              default: _withCtx(() => [
                (_ctx.hasEditPage)
                  ? (_openBlock(), _createBlock(_component_a_button, {
                      key: 0,
                      class: "modal__button",
                      onClick: ($event: any) => (_ctx.showEditForm(record[_ctx.primaryKey]))
                    }, {
                      icon: _withCtx(() => [
                        _createVNode(_component_edit_outlined)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  : (_openBlock(), _createBlock(_component_UpdateContentFormModal, {
                      key: 1,
                      title: _ctx.updateModalTitle || `컨텐츠 수정`,
                      "resource-id": record[_ctx.primaryKey],
                      uri: _ctx.uri,
                      "input-spec": _ctx.updateContentSpec!!,
                      onAfterSubmit: _ctx.reloadPage
                    }, {
                      header: _withCtx((data) => [
                        _renderSlot(_ctx.$slots, "updateModalHeader", _normalizeProps(_guardReactiveProps(data)))
                      ]),
                      footer: _withCtx((data) => [
                        _renderSlot(_ctx.$slots, "updateModalFooter", _normalizeProps(_guardReactiveProps(data)))
                      ]),
                      _: 2
                    }, 1032, ["title", "resource-id", "uri", "input-spec", "onAfterSubmit"])),
                _renderSlot(_ctx.$slots, "edit", {
                  uri: _ctx.uri,
                  search: _ctx.reloadPage,
                  record: record
                })
              ]),
              _: 2
            }, 1024))
          : _createCommentVNode("", true),
        (column.type === 'detail')
          ? (_openBlock(), _createBlock(_component_a_space, { key: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_DetailModal, {
                  "resource-id": record[_ctx.primaryKey],
                  uri: _ctx.uri,
                  "form-spec": {}
                }, {
                  body: _withCtx(({ resourceId, record, fetchResource }) => [
                    _renderSlot(_ctx.$slots, "detail", {
                      resourceId: resourceId,
                      record: record,
                      search: _ctx.reloadPage,
                      fetchResource: fetchResource
                    })
                  ]),
                  _: 2
                }, 1032, ["resource-id", "uri"])
              ]),
              _: 2
            }, 1024))
          : _createCommentVNode("", true),
        (column.type == 'action')
          ? (_openBlock(), _createBlock(_component_a_space, { key: 17 }, {
              default: _withCtx(() => [
                _createVNode(_component_ActionModal, {
                  title: _ctx.actionModalTitle,
                  "resource-id": record[_ctx.primaryKey],
                  uri: _ctx.actionUri!!,
                  "input-spec": _ctx.actionSpec!!,
                  rules: _ctx.actionRules,
                  record: record,
                  disabled: _ctx.actionModalDisabled(record),
                  onAfterSubmit: _ctx.reloadPage,
                  onShowModal: _cache[0] || (_cache[0] = 
                            (data) =>
                                _ctx.$emit('show-action-modal', data)
                        )
                }, {
                  header: _withCtx((data) => [
                    _renderSlot(_ctx.$slots, "actionModalHeader", _normalizeProps(_guardReactiveProps(data)))
                  ]),
                  footer: _withCtx((data) => [
                    _renderSlot(_ctx.$slots, "actionModalFooter", _normalizeProps(_guardReactiveProps(data)))
                  ]),
                  icon: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "actionModalIcon")
                  ]),
                  beforeButton: _withCtx((data) => [
                    _renderSlot(_ctx.$slots, "actionModalBeforeButton", _normalizeProps(_guardReactiveProps(data)))
                  ]),
                  _: 2
                }, 1032, ["title", "resource-id", "uri", "input-spec", "rules", "record", "disabled", "onAfterSubmit"]),
                _renderSlot(_ctx.$slots, "action", {
                  uri: _ctx.uri,
                  search: _ctx.reloadPage,
                  record: record
                })
              ]),
              _: 2
            }, 1024))
          : _createCommentVNode("", true),
        (column.type === 'ad-properties-obj')
          ? (_openBlock(), _createElementBlock("span", _hoisted_40, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.properties, (property, key, index) => {
                return (_openBlock(), _createBlock(_component_a_tag, { key: index }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(key) + "(" + _toDisplayString(property) + ") ", 1)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (column.type === 'ad-instance-event-url')
          ? (_openBlock(), _createElementBlock("span", _hoisted_41, [
              _createVNode(_component_a_tooltip, {
                placement: "top",
                color: _ctx.blue
              }, {
                title: _withCtx(() => [
                  _createElementVNode("a", {
                    href: 
                                    _ctx.sanitizeUrl(
                                        record?.eventHandler?.url ||
                                            'https://www.pillyze.com'
                                    )
                                ,
                    target: "_blank"
                  }, _toDisplayString(record?.eventHandlerUrl), 9, _hoisted_42)
                ]),
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(record?.eventHandlerType), 1)
                ]),
                _: 2
              }, 1032, ["color"])
            ]))
          : _createCommentVNode("", true),
        (column.type === 'ad-layout-supported')
          ? (_openBlock(), _createElementBlock("div", _hoisted_43, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.supportedLayouts, (layout) => {
                return (_openBlock(), _createElementBlock("span", { key: layout }, [
                  _createVNode(_component_a_tag, null, {
                    default: _withCtx(() => [
                      _createTextVNode("[" + _toDisplayString(layout.id) + "] " + _toDisplayString(layout.name), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (column.type === 'ad-instances')
          ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.instances, (instance) => {
                return (_openBlock(), _createElementBlock("span", {
                  key: instance.id
                }, " [" + _toDisplayString(instance.id) + "] " + _toDisplayString(instance.name), 1))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (column.type === 'ad-preview')
          ? (_openBlock(), _createElementBlock("div", _hoisted_45, [
              _createVNode(_component_ad_preview, { instance: record }, null, 8, ["instance"])
            ]))
          : _createCommentVNode("", true),
        (column.type === 'ad-target-user')
          ? (_openBlock(), _createElementBlock("div", _hoisted_46, [
              _createElementVNode("div", null, _toDisplayString(record.targetUser.segId), 1)
            ]))
          : _createCommentVNode("", true),
        (column.type === 'ad-repeat')
          ? (_openBlock(), _createElementBlock("div", _hoisted_47, [
              _createVNode(_component_ad_repeat, { instance: record }, null, 8, ["instance"])
            ]))
          : _createCommentVNode("", true),
        (column.type == 'hasImage')
          ? (_openBlock(), _createBlock(_component_a_space, { key: 25 }, {
              default: _withCtx(() => [
                text
                  ? (_openBlock(), _createBlock(_component_picture_outlined, {
                      key: 0,
                      style: {"font-size":"30px","color":"green"}
                    }))
                  : (_openBlock(), _createBlock(_component_close_outlined, {
                      key: 1,
                      style: {"font-size":"30px","color":"red"}
                    }))
              ]),
              _: 2
            }, 1024))
          : _createCommentVNode("", true),
        (column.type == 'hasImageSize')
          ? (_openBlock(), _createBlock(_component_a_space, { key: 26 }, {
              default: _withCtx(() => [
                (record.imageList.length > 0)
                  ? (_openBlock(), _createBlock(_component_picture_outlined, {
                      key: 0,
                      style: {"font-size":"30px","color":"green"}
                    }))
                  : (_openBlock(), _createBlock(_component_close_outlined, {
                      key: 1,
                      style: {"font-size":"30px","color":"red"}
                    }))
              ]),
              _: 2
            }, 1024))
          : _createCommentVNode("", true),
        (column.type == 'text-ellipsis')
          ? (_openBlock(), _createBlock(_component_a_space, {
              key: 27,
              style: {"white-space":"nowrap","overflow":"scroll","text-overflow":"ellipsis","max-width":"100%"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_span, { class: "ellipsis" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(record[column.dataIndex]), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024))
          : _createCommentVNode("", true),
        (column.type == 'customAction')
          ? (_openBlock(), _createBlock(_component_a_space, { key: 28 }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "customAction", { record: record })
              ]),
              _: 2
            }, 1024))
          : _createCommentVNode("", true),
        (column.type == 'owner' && record.owner)
          ? (_openBlock(), _createBlock(_component_a_space, { key: 29 }, {
              default: _withCtx(() => [
                (record.owner?.profileImageUrl)
                  ? (_openBlock(), _createBlock(_component_a_avatar, {
                      key: 0,
                      src: record.owner?.profileImageUrl
                    }, null, 8, ["src"]))
                  : (record.owner.profileName)
                    ? (_openBlock(), _createBlock(_component_a_avatar, { key: 1 }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_UserOutlined)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                _createTextVNode(" " + _toDisplayString(record.owner?.profileName), 1)
              ]),
              _: 2
            }, 1024))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 16, ["columns", "scroll", "row-selection", "onChange"]),
    _createVNode(_component_router_view)
  ]))
}