import { defineComponent as _defineComponent } from 'vue'
import { mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, unref as _unref, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-fit flex relative" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "image-actions-container" }
const _hoisted_4 = ["content", "onClick"]

import { Editor, Node, NodeViewWrapper } from '@tiptap/vue-3'
import { ref, onMounted, computed, watch } from 'vue'
import { Node as ProseMirrorNode } from 'prosemirror-model'
import { Decoration } from 'prosemirror-view'
import InlineSvg from 'vue-inline-svg'

import { resizableMediaActions } from './resizableMediaMenuUtil'
import { Tippy } from 'vue-tippy'

interface Props {
    editor: Editor
    node: ProseMirrorNode
    decorations: Decoration
    selected: boolean
    extension: Node<any, any>
    getPos: () => number
    updateAttributes: (attributes: Record<string, any>) => void
    deleteNode: () => void
}

interface WidthAndHeight {
    width: number
    height: number
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ResizableMediaNodeView',
  props: {
    editor: {},
    node: {},
    decorations: {},
    selected: { type: Boolean },
    extension: {},
    getPos: { type: Function },
    updateAttributes: { type: Function },
    deleteNode: { type: Function }
  },
  setup(__props: any) {

const props = __props

const mediaType = computed<'img' | 'video'>(
    () => props.node.attrs['media-type']
)

const resizableImg = ref<HTMLImageElement | HTMLVideoElement | null>(null) // template ref

const aspectRatio = ref(0)

const proseMirrorContainerWidth = ref(0)

const mediaActionActiveState = ref<Record<string, boolean>>({})

const setMediaActionActiveStates = () => {
    const activeStates: Record<string, boolean> = {}

    for (const { tooltip, isActive } of resizableMediaActions)
        activeStates[tooltip] = !!isActive?.(props.node.attrs)

    mediaActionActiveState.value = activeStates
}

watch(
    () => props.node.attrs,
    () => setMediaActionActiveStates(),
    { deep: true }
)

const mediaSetupOnLoad = () => {
    // ! TODO: move this to extension storage
    const proseMirrorContainerDiv = document.querySelector('.ProseMirror')

    if (proseMirrorContainerDiv)
        proseMirrorContainerWidth.value = proseMirrorContainerDiv?.clientWidth

    // When the media has loaded
    if (!resizableImg.value) return

    if (mediaType.value === 'video') {
        // Aspect Ratio from its original size
        setTimeout(() => {
            aspectRatio.value =
                (resizableImg.value as HTMLVideoElement).videoWidth /
                (resizableImg.value as HTMLVideoElement).videoHeight

            // for the first time when video is added with custom width and height
            // and we have to adjust the video height according to it's width
            onHorizontalResize('left', 0)
        }, 200)
    } else {
        resizableImg.value.onload = () => {
            // Aspect Ratio from its original size
            aspectRatio.value =
                (resizableImg.value as HTMLImageElement).naturalWidth /
                (resizableImg.value as HTMLImageElement).naturalHeight

            onHorizontalResize('left', 0)
        }
    }

    setTimeout(() => setMediaActionActiveStates(), 200)
}

onMounted(() => mediaSetupOnLoad())

const isHorizontalResizeActive = ref(false)

const lastCursorX = ref(-1)

const limitWidthOrHeightToFiftyPixels = ({ width, height }: WidthAndHeight) =>
    width < 100 || height < 100

const startHorizontalResize = (e: MouseEvent) => {
    isHorizontalResizeActive.value = true
    lastCursorX.value = e.clientX

    document.addEventListener('mousemove', onHorizontalMouseMove)
    document.addEventListener('mouseup', stopHorizontalResize)
}

const stopHorizontalResize = () => {
    isHorizontalResizeActive.value = false
    lastCursorX.value = -1

    document.removeEventListener('mousemove', onHorizontalMouseMove)
    document.removeEventListener('mouseup', stopHorizontalResize)
}

const onHorizontalResize = (
    directionOfMouseMove: 'right' | 'left',
    diff: number
) => {
    if (!resizableImg.value) {
        console.error('Media ref is undefined|null', {
            resizableImg: resizableImg.value,
        })
        return
    }

    const currentMediaDimensions = {
        width: resizableImg.value?.width,
        height: resizableImg.value?.height,
    }

    const newMediaDimensions = {
        width: -1,
        height: -1,
    }

    if (directionOfMouseMove === 'left') {
        newMediaDimensions.width = currentMediaDimensions.width - Math.abs(diff)
    } else {
        newMediaDimensions.width = currentMediaDimensions.width + Math.abs(diff)
    }

    if (newMediaDimensions.width > proseMirrorContainerWidth.value)
        newMediaDimensions.width = proseMirrorContainerWidth.value

    newMediaDimensions.height = newMediaDimensions.width / aspectRatio.value

    if (limitWidthOrHeightToFiftyPixels(newMediaDimensions)) return

    props.updateAttributes(newMediaDimensions)
}

const onHorizontalMouseMove = (e: MouseEvent) => {
    if (!isHorizontalResizeActive.value) return

    const { clientX } = e

    const diff = lastCursorX.value - clientX

    lastCursorX.value = clientX

    if (diff === 0) return

    const directionOfMouseMove: 'left' | 'right' = diff > 0 ? 'left' : 'right'

    onHorizontalResize(directionOfMouseMove, Math.abs(diff))
}

const isVerticalResizeActive = ref(false)

const lastCursorY = ref(-1)

const startVerticalResize = (e: MouseEvent) => {
    isVerticalResizeActive.value = true
    lastCursorY.value = e.clientY

    document.addEventListener('mousemove', onVerticalMouseMove)
    document.addEventListener('mouseup', stopVerticalResize)
}

const stopVerticalResize = () => {
    isVerticalResizeActive.value = false
    lastCursorY.value = -1

    document.removeEventListener('mousemove', onVerticalMouseMove)
    document.removeEventListener('mouseup', stopVerticalResize)
}

const onVerticalMouseMove = (e: MouseEvent) => {
    if (!isVerticalResizeActive.value) return

    const { clientY } = e

    const diff = lastCursorY.value - clientY

    lastCursorY.value = clientY

    if (diff === 0) return

    const directionOfMouseMove: 'up' | 'down' = diff > 0 ? 'up' : 'down'

    if (!resizableImg.value) {
        console.error('Media ref is undefined|null', {
            resizableImg: resizableImg.value,
        })
        return
    }

    const currentMediaDimensions = {
        width: resizableImg.value?.width,
        height: resizableImg.value?.height,
    }

    const newMediaDimensions = {
        width: -1,
        height: -1,
    }

    if (directionOfMouseMove === 'up') {
        newMediaDimensions.height =
            currentMediaDimensions.height - Math.abs(diff)
    } else {
        newMediaDimensions.height =
            currentMediaDimensions.height + Math.abs(diff)
    }

    newMediaDimensions.width = newMediaDimensions.height * aspectRatio.value

    if (newMediaDimensions.width > proseMirrorContainerWidth.value) {
        newMediaDimensions.width = proseMirrorContainerWidth.value

        newMediaDimensions.height = newMediaDimensions.width / aspectRatio.value
    }

    if (limitWidthOrHeightToFiftyPixels(newMediaDimensions)) return

    props.updateAttributes(newMediaDimensions)
}

const isFloat = computed<boolean>(() => !!props.node.attrs.dataFloat)

const isAlign = computed<boolean>(() => !!props.node.attrs.dataAlign)

return (_ctx: any,_cache: any) => {
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createBlock(_unref(NodeViewWrapper), {
    as: "article",
    class: _normalizeClass(["media-node-view flex pos-relative not-prose", [
            `${(isFloat.value && `f-${props.node.attrs.dataFloat}`) || ''}`,
            `${(isAlign.value && `align-${props.node.attrs.dataAlign}`) || ''}`,
        ]])
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(Tippy), { interactive: true }, {
        content: _withCtx(() => [
          _createElementVNode("section", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(resizableMediaActions), (mediaAction, i) => {
              return _withDirectives((_openBlock(), _createElementBlock("button", {
                key: i,
                content: mediaAction.tooltip,
                class: "btn btn-sm btn-ghost image-action-button",
                onClick: ($event: any) => (
                            mediaAction.tooltip === 'Delete'
                                ? mediaAction.delete?.(_ctx.deleteNode)
                                : mediaAction.action?.(_ctx.updateAttributes)
                        )
              }, [
                _createVNode(_unref(InlineSvg), {
                  src: mediaAction.icon
                }, null, 8, ["src"])
              ], 8, _hoisted_4)), [
                [_directive_tippy, {
                            content: mediaAction.tooltip,
                            placement: 'top',
                        }]
              ])
            }), 128))
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (mediaType.value === 'img')
              ? (_openBlock(), _createElementBlock("img", _mergeProps({ key: 0 }, _ctx.node.attrs, {
                  ref_key: "resizableImg",
                  ref: resizableImg,
                  class: ["rounded-lg", [
                        `${
                            (isFloat.value &&
                                `float-${props.node.attrs.dataFloat}`) ||
                            ''
                        }`,
                        `${
                            (isAlign.value &&
                                `align-${props.node.attrs.dataAlign}`) ||
                            ''
                        }`,
                    ]],
                  draggable: "true"
                }), null, 16))
              : (mediaType.value === 'video')
                ? (_openBlock(), _createElementBlock("video", _mergeProps({ key: 1 }, _ctx.node.attrs, {
                    ref_key: "resizableImg",
                    ref: resizableImg,
                    class: ["rounded-lg", [
                        `${
                            (isFloat.value &&
                                `float-${props.node.attrs.dataFloat}`) ||
                            ''
                        }`,
                        `${
                            (isAlign.value &&
                                `align-${props.node.attrs.dataAlign}`) ||
                            ''
                        }`,
                    ]],
                    draggable: "true",
                    controls: "true"
                  }), [
                    _createElementVNode("source", {
                      src: _ctx.node.attrs.src
                    }, null, 8, _hoisted_2)
                  ], 16))
                : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass(["horizontal-resize-handle", {
                        'horizontal-resize-active': isHorizontalResizeActive.value,
                    }]),
              title: "Resize",
              onMousedown: startHorizontalResize,
              onMouseup: stopHorizontalResize
            }, null, 34),
            _createElementVNode("div", {
              class: _normalizeClass(["vertical-resize-handle", {
                        'vertical-resize-active': isVerticalResizeActive.value,
                    }]),
              title: "Resize",
              onMousedown: startVerticalResize,
              onMouseup: stopVerticalResize
            }, null, 34)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["class"]))
}
}

})