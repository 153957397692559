import { ref } from 'vue'
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { formatKoreanTime } from '@/util/formmater'
import { format } from 'date-fns'
import { message as log } from 'ant-design-vue'

type MessageType = {
    id: string
    message: string
    time: string
    isLeft?: boolean
    srcList?: Array<string>
    mediaType?: 'IMAGE' | 'VIDEO' | 'HTML'
    profileImageUrl?: string
    userName?: string
    needHr?: boolean
    creationTime?: Date
    loading?: boolean
}

type Timeline = {
    title: string
    date: string
}

const unreadCount = ref<Record<string, number>>({
    WM_MESSAGE: 0,
    QNA: 0,
})

const fetchUnreadCount = async (formeMessageType: string, userId: number) => {
    const response = await adminApi<ApiResponse<number>>(
        `/api/forme/diet/chat/unread/${formeMessageType}/${userId}`
    )
    unreadCount.value[formeMessageType] = response.result || 0
}

const readAllMessage = async (formeMessageType: string, userId: number) => {
    await adminApi<ApiResponse<null>>(
        `/api/forme/diet/chat/read/${formeMessageType}/${userId}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
}

const messageList = ref<Array<MessageType>>([])
const timelineList = ref<Array<Timeline>>([])

const fetchMessageList = async (userId: number, formeMessageType: string) => {
    const response = await adminApi<ApiResponse<any>>(
        `/api/forme/diet/chat/${userId}?formeMessageType=${formeMessageType}`
    )
    const existsIds = messageList.value.map((m) => m.id)
    const result = response.result
    result
        .filter((it: any) => !existsIds.includes(it.id))
        .forEach((it: any, index: number) => {
            let dateChanged = false
            if (index > 0) {
                const prev = result[index - 1]
                if (
                    format(prev.creationTime, 'yyyy-MM-dd') !==
                    format(it.creationTime, 'yyyy-MM-dd')
                ) {
                    dateChanged = true
                }
            }

            messageList.value.push({
                id: it.id,
                message: it.message,
                time: formatKoreanTime(it.creationTime),
                isLeft: it.byUser,
                srcList: it.mediaList.map((m: any) => m.url),
                mediaType: it.formeMessageContentType,
                profileImageUrl: it.userProfileImageUrl,
                userName: it.userUserName,
                needHr: dateChanged,
                creationTime: it.creationTime,
            })
        })
    const groupByDate = result.reduce((acc: any, it: any) => {
        const date = format(it.creationTime, 'yyyy-MM-dd')
        if (acc[date] == null) {
            acc[date] = []
        }
        acc[date].push(it)
        return acc
    }, {})

    timelineList.value.length = 0
    timelineList.value.push(
        ...Object.entries(groupByDate).map(([date, list]: [string, any]) => ({
            title: list[0].message,
            date,
        }))
    )
}

const loadingMessage = ref(false)

const writeMessage = async (
    userId: number,
    formeMesageType: string,
    formeMessageContentType: string,
    message?: string
) => {
    loadingMessage.value = true
    try {
        await adminApi(`/api/forme/diet/message`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userId: userId,
                formeMessageType: formeMesageType,
                formeMessageContentType,
                message,
                targetDate: format(new Date(), 'yyyy-MM-dd'),
            }),
        })
    } catch (e) {
        log.error('메세지 전송에 실패했습니다.')
    }
    loadingMessage.value = false
}

const cleanMessageList = () => {
    messageList.value.length = 0
    timelineList.value.length = 0
}

const qnaEnable = ref(false)

const fetchQnaEnable = async (userId: number) => {
    const response = await adminApi<ApiResponse<boolean>>(
        `/api/forme/diet/chat/qna/enable/${userId}`
    )
    qnaEnable.value = response.result || false
}

export default {
    unreadCount,
    fetchUnreadCount,
    readAllMessage,
    messageList,
    fetchMessageList,
    timelineList,
    writeMessage,
    loadingMessage,
    cleanMessageList,
    fetchQnaEnable,
    qnaEnable,
}
